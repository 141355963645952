import { Box, Button, HStack, Text } from "@chakra-ui/react";
import React from "react";
import moment from "moment";

const LogLoanModal = ({ loanLogs, limit, setLimit, getLogs, processing }) => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <>
      <Box bg="#FFFFFF" mt="20px" borderRadius="10px">
        <Text
          color="#540A45"
          fontWeight="700"
          fontFamily="satoshiBolder"
          p="10px 20px"
          fontSize="14px"
        >
          Loan Action Logs
        </Text>

        <Box>
          {loanLogs?.length === 0 && (
            <Text
              p="20px"
              textAlign="center"
              color="#33343D"
              fontSize="14px"
              fontFamily="satoshiBold"
            >
              No logs available
            </Text>
          )}
        </Box>

        {(loanLogs || []).map((logInfo) =>
          logInfo.type === "LOAN_REQUEST_ACTION" ? (
            <HStack key={logInfo.id} p="20px">
              <Box>
                <Text
                  opacity="0.81px"
                  fontWeight="500"
                  fontSize="10px"
                  color="#666666"
                  lineHeight="18px"
                  textTransform="uppercase"
                >
                  ADMIN USER{" "}
                </Text>
                <Text
                  fontWeight="500"
                  fontSize="14px"
                  color="#33343D"
                  lineHeight="18px"
                  fontFamily="satoshiBold"
                >
                  {logInfo?.admin_name || "-"} - {logInfo?.admin_role}
                </Text>
              </Box>

              <Box ml="50px">
                <Text
                  opacity="0.81px"
                  fontWeight="500"
                  fontSize="10px"
                  color="#666666"
                  lineHeight="18px"
                  textTransform="uppercase"
                >
                  ACTION
                </Text>
                <Text
                  fontWeight="500"
                  fontSize="14px"
                  color="#33343D"
                  lineHeight="18px"
                  fontFamily="satoshiBold"
                >
                  {logInfo?.resource || "-"}
                </Text>
              </Box>

              <Box ml="50px">
                <Text
                  opacity="0.81px"
                  fontWeight="500"
                  fontSize="10px"
                  color="#666666"
                  lineHeight="18px"
                  textTransform="uppercase"
                >
                  DATE
                </Text>
                <Text
                  fontWeight="500"
                  fontSize="14px"
                  color="#33343D"
                  lineHeight="18px"
                  fontFamily="satoshiBold"
                >
                  {moment(logInfo?.created_at).format(
                    "Do MMMM, YYYY ; hh:mm a"
                  ) || "-"}
                </Text>
              </Box>
            </HStack>
          ) : logInfo.type === "UPLOAD" ? (
            <HStack p="20px">
              <Box>
                <Text
                  opacity="0.81px"
                  fontWeight="500"
                  fontSize="10px"
                  color="#666666"
                  lineHeight="18px"
                  textTransform="uppercase"
                >
                  ADMIN USER{" "}
                </Text>
                <Text
                  fontWeight="500"
                  fontSize="14px"
                  color="#33343D"
                  lineHeight="18px"
                  fontFamily="satoshiBold"
                >
                  {logInfo?.admin_name || "-"} - {logInfo?.admin_role}
                </Text>
              </Box>

              <Box ml="50px">
                <Text
                  opacity="0.81px"
                  fontWeight="500"
                  fontSize="10px"
                  color="#666666"
                  lineHeight="18px"
                  textTransform="uppercase"
                >
                  ACTION
                </Text>
                <Text
                  cursor={"pointer"}
                  onClick={() => openInNewTab(logInfo.resource)}
                  fontWeight="500"
                  fontSize="14px"
                  color="#33343D"
                  lineHeight="18px"
                  fontFamily="satoshiBold"
                >
                  {logInfo?.resource || "-"}
                </Text>
              </Box>

              <Box ml="50px">
                <Text
                  opacity="0.81px"
                  fontWeight="500"
                  fontSize="10px"
                  color="#666666"
                  lineHeight="18px"
                  textTransform="uppercase"
                >
                  DATE
                </Text>
                <Text
                  fontWeight="500"
                  fontSize="14px"
                  color="#33343D"
                  lineHeight="18px"
                  fontFamily="satoshiBold"
                >
                  {moment(logInfo?.created_at).format(
                    "Do MMMM, YYYY ; hh:mm a"
                  ) || "-"}
                </Text>
              </Box>
            </HStack>
          ) : (
            <HStack p="20px">
              <Box>
                <Text
                  opacity="0.81px"
                  fontWeight="500"
                  fontSize="10px"
                  color="#666666"
                  lineHeight="18px"
                  textTransform="uppercase"
                >
                  ADMIN USER{" "}
                </Text>
                <Text
                  fontWeight="500"
                  fontSize="14px"
                  color="#33343D"
                  lineHeight="18px"
                  fontFamily="satoshiBold"
                >
                  {logInfo?.admin_name || "-"} - {logInfo?.admin_role}
                </Text>
              </Box>

              <Box ml="50px">
                <Text
                  opacity="0.81px"
                  fontWeight="500"
                  fontSize="10px"
                  color="#666666"
                  lineHeight="18px"
                  textTransform="uppercase"
                >
                  ACTION
                </Text>
                <Text
                  fontWeight="500"
                  fontSize="14px"
                  color="#33343D"
                  lineHeight="18px"
                  fontFamily="satoshiBold"
                >
                  {logInfo?.resource || "-"}
                </Text>
              </Box>

              <Box ml="50px">
                <Text
                  opacity="0.81px"
                  fontWeight="500"
                  fontSize="10px"
                  color="#666666"
                  lineHeight="18px"
                  textTransform="uppercase"
                >
                  DATE
                </Text>
                <Text
                  fontWeight="500"
                  fontSize="14px"
                  color="#33343D"
                  lineHeight="18px"
                  fontFamily="satoshiBold"
                >
                  {moment(logInfo?.created_at).format(
                    "Do MMMM, YYYY ; hh:mm a"
                  ) || "-"}
                </Text>
              </Box>
            </HStack>
          )
        )}

        <Box>
          {processing && (
            <Box>
              <Text
                p="20px"
                textAlign="center"
                color="#33343D"
                fontSize="14px"
                fontFamily="satoshiBold"
              >
                Loading...
              </Text>
            </Box>
          )}
        </Box>

        <Box>
          {!processing && (
            <>
              {limit === loanLogs?.length && (
                <Box>
                  <Button
                    onClick={async () => {
                      const newLimit = limit + 10;
                      setLimit(newLimit);
                      await getLogs(newLimit);
                    }}
                    bg="#540A45"
                    color="#FFFFFF"
                    p="10px 20px"
                    m="20px"
                    borderRadius="5px"
                    mt="20px"
                  >
                    <Text>LOAD MORE</Text>
                  </Button>
                </Box>
              )}

              {loanLogs?.length > 0 && (
                <>
                  {limit > loanLogs?.length && (
                    <Text
                      p="20px"
                      textAlign="center"
                      color="#33343D"
                      fontSize="14px"
                      fontFamily="satoshiBold"
                    >
                      No more logs to show
                    </Text>
                  )}
                </>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default LogLoanModal;
