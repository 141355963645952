import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Flex,
} from "@chakra-ui/react";
import React from "react";
import { BsThreeDotsVertical, BsChevronBarRight } from "react-icons/bs";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { formatMoney } from "../services/utils/helpersFunc";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
const ListofLoans = () => {
  const navigate = useNavigate();

  const role = localStorage.getItem("role");
  const { loans } = useSelector((state) => state.loanRequest);

  return (
    <Box bg="white" mt="20px">
      {localStorage.getItem("role") === "Admin" ? (
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th
                  textTransform="capitalize"
                  fontSize={["10px", "12px"]}
                  color="#262B40"
                  lineHeight="18px"
                  fontWeight="500"
                >
                  {" "}
                  Date{" "}
                </Th>
                <Th
                  textTransform="capitalize"
                  fontSize={["10px", "12px"]}
                  color="#262B40"
                  lineHeight="18px"
                  fontWeight="500"
                >
                  {" "}
                  Loan ID
                </Th>
                <Th
                  textTransform="capitalize"
                  fontSize={["10px", "12px"]}
                  color="#262B40"
                  lineHeight="18px"
                  fontWeight="500"
                >
                  Insurance Fee
                </Th>
                <Th
                  textTransform="capitalize"
                  fontSize={["10px", "12px"]}
                  color="#262B40"
                  lineHeight="18px"
                  fontWeight="500"
                >
                  Processing Fee
                </Th>
                <Th
                  textTransform="capitalize"
                  fontSize={["10px", "12px"]}
                  color="#262B40"
                  lineHeight="18px"
                  fontWeight="500"
                >
                  Proposed Amount
                </Th>
                <Th
                  textTransform="capitalize"
                  fontSize={["10px", "12px"]}
                  color="#262B40"
                  lineHeight="18px"
                  fontWeight="500"
                >
                  Proposed Tenure
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              {loans
                ?.slice(0)
                .reverse()
                .map((item) => {
                  return (
                    <motion.tr
                      key={item.request_id}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.9 }}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => navigate(`/requests/${item.request_id}`)}
                    >
                      <Td
                        fontSize={["10px", "12px"]}
                        color="#33343D"
                        lineHeight="18px"
                        fontWeight="500"
                        fontFamily="satoshiBold"
                      >
                        {" "}
                        {moment(item.created_at).format("L")}{" "}
                      </Td>
                      <Td
                        fontSize={["10px", "12px"]}
                        color="#33343D"
                        lineHeight="18px"
                        fontWeight="500"
                        fontFamily="satoshiBold"
                      >
                        {" "}
                        {item.request_id}
                      </Td>
                      <Td
                        fontSize={["10px", "12px"]}
                        color="#33343D"
                        lineHeight="18px"
                        fontWeight="500"
                        fontFamily="satoshiBold"
                      >
                        {item.insurance_fee}
                      </Td>
                      <Td
                        fontSize={["10px", "12px"]}
                        color="#33343D"
                        lineHeight="18px"
                        fontWeight="500"
                        fontFamily="satoshiBold"
                      >
                        {item.processing_fee}
                      </Td>
                      <Td
                        fontSize={["10px", "12px"]}
                        color="#33343D"
                        lineHeight="18px"
                        fontWeight="500"
                        fontFamily="satoshiBold"
                      >
                        {item.proposed_amount}
                      </Td>
                      <Td
                        fontSize={["10px", "12px"]}
                        color="#33343D"
                        lineHeight="18px"
                        fontWeight="500"
                        fontFamily="satoshiBold"
                      >
                        {item.proposed_tenure}
                      </Td>

                      {/* <Td>

                                                {
                                                    role === 'Reporting' ?
                                                        (item.sales_approval === 'pending') ?
                                                            <Text bg="#FFF4D6" color="#FF8A25" p="5px 10px" fontSize="10px" borderRadius="3px" fontWeight="500" fontFamily="satoshiBold" textAlign="center">Pending</Text>
                                                            :
                                                            (item.sales_approval === 'approved') ?
                                                                <Text bg="#D3FDF0" color="#09A775" p="5px 10px" fontSize="10px" borderRadius="3px" fontWeight="500" fontFamily="satoshiBold" textAlign="center">Approved</Text>
                                                                :
                                                                <Text bg="#FFE0E0" color="#FF4949" p="5px 10px" fontSize="10px" borderRadius="3px" fontWeight="500" fontFamily="satoshiBold" textAlign="center">Rejected</Text>
                                                        :
                                                        role === 'Finance Ops' ?
                                                            (item.credit_approval === 'rejected') ?
                                                                <Text bg="#FFE0E0" color="#FF4949" p="5px 10px" fontSize="10px" borderRadius="3px" fontWeight="500" fontFamily="satoshiBold" textAlign="center">Rejected</Text>
                                                                :
                                                                (item.credit_approval === 'approved') ?
                                                                    <Text bg="#D3FDF0" color="#09A775" p="5px 10px" fontSize="10px" borderRadius="3px" fontWeight="500" fontFamily="satoshiBold" textAlign="center">Approved</Text>
                                                                    :
                                                                    <Text bg="#FFF4D6" color="#FF8A25" p="5px 10px" fontSize="10px" borderRadius="3px" fontWeight="500" fontFamily="satoshiBold" textAlign="center">Pending</Text>
                                                            :
                                                            role === 'Finance Admin' &&
                                                                (item.finance_approval === 'rejected') ?
                                                                <Text bg="#FFE0E0" color="#FF4949" p="5px 10px" fontSize="10px" borderRadius="3px" fontWeight="500" fontFamily="satoshiBold" textAlign="center">Rejected</Text>
                                                                :
                                                                (item.finance_approval === 'approved') ?
                                                                    <Text bg="#D3FDF0" color="#09A775" p="5px 10px" fontSize="10px" borderRadius="3px" fontWeight="500" fontFamily="satoshiBold" textAlign="center">Approved</Text>
                                                                    :

                                                                    <Text bg="#FFF4D6" color="#FF8A25" p="5px 10px" fontSize="10px" borderRadius="3px" fontWeight="500" fontFamily="satoshiBold" textAlign="center">Pending</Text>

                                                }

                                            </Td> */}

                      <Td>
                        <BsChevronBarRight />
                      </Td>
                    </motion.tr>
                  );
                })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th
                  textTransform="capitalize"
                  fontSize={["10px", "12px"]}
                  color="#262B40"
                  lineHeight="18px"
                  fontWeight="500"
                >
                  {" "}
                  Date{" "}
                </Th>
                <Th
                  textTransform="capitalize"
                  fontSize={["10px", "12px"]}
                  color="#262B40"
                  lineHeight="18px"
                  fontWeight="500"
                >
                  {" "}
                  First Name
                </Th>
                <Th
                  textTransform="capitalize"
                  fontSize={["10px", "12px"]}
                  color="#262B40"
                  lineHeight="18px"
                  fontWeight="500"
                >
                  Last Name
                </Th>
                <Th
                  textTransform="capitalize"
                  fontSize={["10px", "12px"]}
                  color="#262B40"
                  lineHeight="18px"
                  fontWeight="500"
                >
                  Date of birth
                </Th>
                <Th
                  textTransform="capitalize"
                  fontSize={["10px", "12px"]}
                  color="#262B40"
                  lineHeight="18px"
                  fontWeight="500"
                >
                  Email
                </Th>
                <Th
                  textTransform="capitalize"
                  fontSize={["10px", "12px"]}
                  color="#262B40"
                  lineHeight="18px"
                  fontWeight="500"
                >
                  Phone Number
                </Th>
                <Th
                  textTransform="capitalize"
                  fontSize={["10px", "12px"]}
                  color="#262B40"
                  lineHeight="18px"
                  fontWeight="500"
                >
                  Loan Amount
                </Th>
                <Th
                  textTransform="capitalize"
                  fontSize={["10px", "12px"]}
                  color="#262B40"
                  lineHeight="18px"
                  fontWeight="500"
                >
                  Status
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              {loans?.map((item) => {
                return (
                  <Tr
                    key={item.request_id}
                    cursor="pointer"
                    onClick={() => navigate(`/requests/${item.request_id}`)}
                  >
                    <Td
                      fontSize={["10px", "12px"]}
                      color="#33343D"
                      lineHeight="18px"
                      fontWeight="500"
                      fontFamily="satoshiBold"
                    >
                      {" "}
                      {moment(item.created_at).format("L")}
                    </Td>
                    <Td
                      fontSize={["10px", "12px"]}
                      color="#33343D"
                      lineHeight="18px"
                      fontWeight="500"
                      fontFamily="satoshiBold"
                    >
                      {" "}
                      {item.first_name}
                    </Td>
                    <Td
                      fontSize={["10px", "12px"]}
                      color="#33343D"
                      lineHeight="18px"
                      fontWeight="500"
                      fontFamily="satoshiBold"
                    >
                      {item.last_name}
                    </Td>
                    <Td
                      fontSize={["10px", "12px"]}
                      color="#33343D"
                      lineHeight="18px"
                      fontWeight="500"
                      fontFamily="satoshiBold"
                    >
                      {moment(item.dob).format("L")}
                    </Td>
                    <Td
                      fontSize={["10px", "12px"]}
                      color="#33343D"
                      lineHeight="18px"
                      fontWeight="500"
                      fontFamily="satoshiBold"
                    >
                      {item.email}
                    </Td>
                    <Td
                      fontSize={["10px", "12px"]}
                      color="#33343D"
                      lineHeight="18px"
                      fontWeight="500"
                      fontFamily="satoshiBold"
                    >
                      {item.phone}
                    </Td>
                    <Td
                      fontSize={["10px", "12px"]}
                      color="#33343D"
                      lineHeight="18px"
                      fontWeight="500"
                      fontFamily="satoshiBold"
                    >
                      {item.amount && `₦ ${formatMoney(item?.amount)}`}
                    </Td>
                    <Td>
                      {role === "Reporting" ? (
                        item.sales_approval === "pending" ? (
                          <Text
                            bg="#FFF4D6"
                            color="#FF8A25"
                            p="5px 10px"
                            fontSize="10px"
                            borderRadius="3px"
                            fontWeight="500"
                            fontFamily="satoshiBold"
                            textAlign="center"
                          >
                            Pending
                          </Text>
                        ) : item.sales_approval === "approved" ? (
                          <Text
                            bg="#D3FDF0"
                            color="#09A775"
                            p="5px 10px"
                            fontSize="10px"
                            borderRadius="3px"
                            fontWeight="500"
                            fontFamily="satoshiBold"
                            textAlign="center"
                          >
                            Approved
                          </Text>
                        ) : (
                          <Text
                            bg="#FFE0E0"
                            color="#FF4949"
                            p="5px 10px"
                            fontSize="10px"
                            borderRadius="3px"
                            fontWeight="500"
                            fontFamily="satoshiBold"
                            textAlign="center"
                          >
                            Rejected
                          </Text>
                        )
                      ) : role === "Finance Ops" ? (
                        item.credit_approval === "rejected" ? (
                          <Text
                            bg="#FFE0E0"
                            color="#FF4949"
                            p="5px 10px"
                            fontSize="10px"
                            borderRadius="3px"
                            fontWeight="500"
                            fontFamily="satoshiBold"
                            textAlign="center"
                          >
                            Rejected
                          </Text>
                        ) : item.credit_approval === "approved" ? (
                          <Text
                            bg="#D3FDF0"
                            color="#09A775"
                            p="5px 10px"
                            fontSize="10px"
                            borderRadius="3px"
                            fontWeight="500"
                            fontFamily="satoshiBold"
                            textAlign="center"
                          >
                            Approved
                          </Text>
                        ) : (
                          <Text
                            bg="#FFF4D6"
                            color="#FF8A25"
                            p="5px 10px"
                            fontSize="10px"
                            borderRadius="3px"
                            fontWeight="500"
                            fontFamily="satoshiBold"
                            textAlign="center"
                          >
                            Pending
                          </Text>
                        )
                      ) : role === "Finance Admin" &&
                        item.finance_approval === "rejected" ? (
                        <Text
                          bg="#FFE0E0"
                          color="#FF4949"
                          p="5px 10px"
                          fontSize="10px"
                          borderRadius="3px"
                          fontWeight="500"
                          fontFamily="satoshiBold"
                          textAlign="center"
                        >
                          Rejected
                        </Text>
                      ) : item.finance_approval === "approved" ? (
                        <Text
                          bg="#D3FDF0"
                          color="#09A775"
                          p="5px 10px"
                          fontSize="10px"
                          borderRadius="3px"
                          fontWeight="500"
                          fontFamily="satoshiBold"
                          textAlign="center"
                        >
                          Approved
                        </Text>
                      ) : (
                        <Text
                          bg="#FFF4D6"
                          color="#FF8A25"
                          p="5px 10px"
                          fontSize="10px"
                          borderRadius="3px"
                          fontWeight="500"
                          fontFamily="satoshiBold"
                          textAlign="center"
                        >
                          Pending
                        </Text>
                      )}
                    </Td>
                    <Td>
                      <BsThreeDotsVertical />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <Flex></Flex>
    </Box>
  );
};

export default ListofLoans;
