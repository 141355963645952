import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getInstantLoanRequests } from "../../services/axios";
const initialState = {
  loading: false,
  error: false,
  success: false,
  message: null,
  instantLoans: [],
  meta: {},
};

export const getInstantLoanRequestAction = createAsyncThunk(
  "getInstantLoanRequestAction",
  async (query) => {
    return await getInstantLoanRequests(query);
  }
);

//CREATE THE SLICE

export const instantLoanRequestSlice = createSlice({
  name: "instantLoanRequest",
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.error = false;
      state.success = false;
      state.message = "";
      state.instantLoans = [];
      state.meta = {};
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getInstantLoanRequestAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInstantLoanRequestAction.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.instantLoans = action.payload?.data?.data;
        state.meta = action.payload?.data?.meta;
      })
      .addCase(getInstantLoanRequestAction.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.message = action.payload;
        state.instantLoans = [];
        state.meta = {};
      });
  },
});

export const { reset } = instantLoanRequestSlice.actions;

export default instantLoanRequestSlice.reducer;
