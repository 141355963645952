import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import ListofLoans from "./ListofLoans";
import { Flex, HStack, Text } from "@chakra-ui/react";
import { BiChevronDown } from "react-icons/bi";
import { Select } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { getLoanRequestAction } from "../redux/reducers/loanRequestReducer";
import { getDeclinedLoanRequestAction } from "../redux/reducers/declinedLoanRequest";
import { useLocation } from "react-router-dom";

function PaginatedLoans({ loans, meta }) {
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const location = useLocation();
  const path = location.pathname;

  console.log(path, "THE PATH");

  const dispatch = useDispatch();

  const handlePageClick = (event) => {
    const selectedPage = event.selected;

    if (path === "/rejected") {
      dispatch(
        getDeclinedLoanRequestAction({
          page: selectedPage + 1,
          pageLimit: 50,
        })
      );
      return;
    }

    dispatch(
      getLoanRequestAction({
        page: selectedPage + 1,
        pageLimit: 50,
      })
    );
  };

  return (
    <>
      <ListofLoans />
      {loans?.length > 0 && (
        <Flex
          justifyContent="space-between"
          flexWrap="wrap"
          bg="#FFFFFF"
          mt="5px"
          p="10px 20px"
        >
          <HStack>
            <Select
              icon={<BiChevronDown />}
              bg="#F8F8F8"
              border="none"
              borderRadius="5px"
              color="#666666"
              opacity="0.85"
              fontWeight="700"
              fontSize="12px"
              w="80px"
              h="30px"
              onChange={(e) => {
                setItemsPerPage(e.target.value);
              }}
            >
              <option value={10}>10</option>
              {loans.length > 10 && <option value={20}>20</option>}
              {loans.length > 20 && <option value={30}>30</option>}
              {loans.length > 30 && <option value={40}>40</option>}
              {loans.length > 50 && <option value={50}>50</option>}
            </Select>

            <Text
              color="#666666"
              opacity="0.85"
              fontWeight="700"
              fontSize="12px"
            >
              Showing 1-{itemsPerPage} of {loans?.length} Enteries
            </Text>
          </HStack>

          <ReactPaginate
            breakLabel="..."
            nextLabel="Next > "
            onPageChange={(event) => handlePageClick(event)}
            pageRangeDisplayed={2}
            pageCount={meta?.totalPages}
            previousLabel=" < Prev"
            previousClassName="previousClassName"
            nextClassName="nextClassName"
            breakClassName="breakClassName"
            pageLinkClassName="pageLinkClassName"
            pageClassName="pageClassName"
            containerClassName="containerClassName"
            activeLinkClassName="activeLinkClassName"
            disabledClassName="disabledClassName"
            renderOnZeroPageCount={null}
          />
        </Flex>
      )}
    </>
  );
}

export default PaginatedLoans;
