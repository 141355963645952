import React, { useEffect, useState } from "react";
import moment from "moment";
import { Box, SimpleGrid, Text, Spinner } from "@chakra-ui/react";
import { formatMoney } from "../../services/utils/helpersFunc";
import { getGuarantorDetails } from "../../services/axios";

const GuarantorInformation = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [guarantorInfo, setGuarantorInfo] = useState({});

  useEffect(() => {
    const getGuarantor = async () => {
      setLoading(true);
      const res = await getGuarantorDetails(id);
      if (res) {
        setGuarantorInfo(res?.data);
      }
      setLoading(false);
      return;
    };
    getGuarantor();
  }, [id]);
  return (
    <Box bg="#FFFFFF" mt="20px" borderRadius="10px">
      <Text
        color="#540A45"
        fontWeight="700"
        fontFamily="satoshiBolder"
        p="10px 20px"
        fontSize="14px"
      >
        Guarantor's Information
      </Text>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        {loading && <Spinner size="md" />}

        {!loading && !guarantorInfo && (
          <Box>
            <Text p="10px 20px">NO GUARANTOR INFORMATION AVAILABLE </Text>
          </Box>
        )}
      </Box>

      {!loading && guarantorInfo && (
        <>
          <SimpleGrid
            spacing="20px"
            minChildWidth={["100px", "70px", "50px"]}
            p="20px"
            border="1px solid #E4E8F2"
          >
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                FULL NAME
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {guarantorInfo?.name}
              </Text>
            </Box>
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                PHONE NUMBER
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {guarantorInfo?.phone}
              </Text>
            </Box>
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                EMAIL
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {guarantorInfo?.email}
              </Text>{" "}
            </Box>
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                SEX
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {guarantorInfo?.gender}
              </Text>
            </Box>
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                MARITAL STATUS
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {guarantorInfo?.marital_status}
              </Text>
            </Box>
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                DATE OF BIRTH
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {/* {loanRequest?.dob} */}
                {moment(guarantorInfo?.date_of_birth).format("Do MMMM YYYY")}
              </Text>
            </Box>
          </SimpleGrid>

          <SimpleGrid
            spacing="20px"
            minChildWidth={["100px", "70px", "50px"]}
            p="20px"
            border="1px solid #E4E8F2"
          >
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                BUSINESS NAME
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {guarantorInfo?.business_name}
              </Text>
            </Box>
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                BUSINESS ADDRESS
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {guarantorInfo?.business_address}
              </Text>
            </Box>
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
                textTransform="uppercase"
              >
                BUSINESS PHONE NUMBER
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {guarantorInfo?.business_phone || "-"}
              </Text>
            </Box>
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                Years IN BUSINESS
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {`${
                  guarantorInfo?.years_in_business &&
                  `${guarantorInfo?.years_in_business} Years`
                }` || "-"}
              </Text>
            </Box>
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                MONTHLY NET INCOME
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                ₦ {formatMoney(guarantorInfo?.monthly_net_income) || "-"}
              </Text>
            </Box>
          </SimpleGrid>

          <SimpleGrid
            spacing="20px"
            minChildWidth={["100px", "70px", "50px"]}
            p="20px"
            border="1px solid #E4E8F2"
          >
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                GOVERNMENT ID
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                <a
                  href={guarantorInfo?.government_id}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Text
                    fontWeight="500"
                    fontSize="14px"
                    color="#5B2E4F"
                    textDecoration="underline"
                    lineHeight="18px"
                    fontFamily="satoshiBold"
                  >
                    {guarantorInfo?.government_id || "-"}
                  </Text>
                </a>
              </Text>
            </Box>
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
                textTransform="uppercase"
              >
                UTILITY BILL
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                <a
                  href={guarantorInfo?.utility_bill}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Text
                    fontWeight="500"
                    fontSize="14px"
                    color="#5B2E4F"
                    textDecoration="underline"
                    lineHeight="18px"
                    fontFamily="satoshiBold"
                  >
                    {guarantorInfo?.utility_bill || "-"}
                  </Text>
                </a>
              </Text>
            </Box>
          </SimpleGrid>
        </>
      )}
    </Box>
  );
};

export default GuarantorInformation;
