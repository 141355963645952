import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  VStack,
  Button,
  Input,
  Divider,
  useToast,
  InputGroup,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { login } from "../services/axios";

const Signin = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const loginAdmin = async () => {
    if (!(email?.trim() && password?.trim())) {
      toast({
        title: "Login Error",
        description: "Fill in your details to login",
        position: "top-left",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    if (password.length <= 7) {
      toast({
        title: "Login Error",
        description: "Password should be greater than 7 characters",
        position: "top-left",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true);
    try {
      const res = await login({ email: email, password: password });
      if (res) {
        const { data } = res;
        localStorage.setItem("name", data?.admin?.name);
        localStorage.setItem("token", data?.token); // Save JWT token
        localStorage.setItem("role", data?.admin?.user_role);

        toast({
          title: "Login successfully",
          description: data?.message,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-left",
        });

        setPassword("");
        setEmail("");
        navigate("/dashboard");
      }
    } catch (error) {
      toast({
        title: "Login Error",
        description: error.message,
        position: "top-left",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="loginBg">
      <Text
        pt="30px"
        color="#540A45"
        fontSize={["14px", "21px"]}
        fontWeight="700"
        fontFamily="SatoshiBolder"
        lineHeight="28.35px"
        textAlign={["left", "center"]}
      >
        Welcome to Loan Admin Dashboard
      </Text>
      <form onSubmit={loginAdmin}>
        <Flex justify="center" alignItems="center" mt="30px">
          <Box
            w={["100%", "80%", "50%"]}
            boxShadow="0px 4px 12px 0px #D7D7D740"
            border="0.5px solid #EFEAED"
            borderRadius="10px"
            p="30px"
          >
            <Text
              fontSize={["12px", "14px"]}
              fontWeight={["500", "700"]}
              color="#33343D"
            >
              Login
            </Text>
            <VStack spacing={5} w="100%">
              <Box mt="20px" w="100%">
                <Text fontWeight="500" fontSize="14px" color="#33343D">
                  Email
                </Text>
                <Input
                  mt="10px"
                  className="form-input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Box>
              <Box mt="20px" w="100%">
                <Text fontWeight="500" fontSize="14px" color="#33343D">
                  Password
                </Text>
                <InputGroup mt="10px" className="input_container">
                  <Input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type={show ? "text" : "password"}
                    variant="unstyled"
                  />
                  <Button h="1.75rem" size="sm" onClick={handleClick}>
                    {show ? "Hide" : "Show"}
                  </Button>
                </InputGroup>
              </Box>
            </VStack>
            <Divider
              mt="50px"
              color="#5B2E4F"
              opacity="85%"
              height="2px"
              width="100%"
            />
            <Flex justify="flex-end" mt="30px">
              {isLoading ? (
                <Button
                  isLoading
                  loadingText="submitting"
                  bg="#5B2E4F"
                  borderRadius="6px"
                  fontSize="12px"
                  fontWeight="500"
                  color="white"
                  p="13px 52px"
                  _hover={{
                    color: "#5B2E4F",
                    border: "1px solid #5B2E4F",
                    bg: "white",
                  }}
                >
                  NEXT
                </Button>
              ) : (
                <Button
                  onClick={loginAdmin}
                  bg="#5B2E4F"
                  type="submit"
                  borderRadius="6px"
                  fontSize="12px"
                  fontWeight="500"
                  color="white"
                  p="13px 52px"
                  _hover={{
                    color: "#5B2E4F",
                    border: "1px solid #5B2E4F",
                    bg: "white",
                  }}
                >
                  NEXT
                </Button>
              )}
            </Flex>
          </Box>
        </Flex>
      </form>
    </div>
  );
};

export default Signin;
