import { Button, Box, Text, Textarea, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import {
  AdminRejection,
  CreditRejection,
  FinanceRejection,
  SalesRejection,
} from "../../services/axios";
import ModalLayout from "../Layout/ModalLayout";

const RejectionModal = ({ id }) => {
  const toast = useToast();
  const role = localStorage.getItem("role");

  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const reject = async () => {
    try {
      if (role === "Reporting") {
        setLoading(true);
        const data = await SalesRejection(id, reason);

        if (data) {
          toast({
            title: "Sales Rejection Successful",
            description: data?.message,
            status: "success",
            position: "top-left",
            duration: 4000,
            isClosable: true,
          });

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } else if (role === "Finance Admin") {
        setLoading(true);
        const data = await FinanceRejection(id, reason);

        if (data) {
          toast({
            title: "Finance Rejection Successful",
            description: data?.message,
            status: "success",
            position: "top-left",
            duration: 4000,
            isClosable: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } else if (role === "Admin") {
        setLoading(true);
        const data = await AdminRejection(id, reason);

        if (data) {
          toast({
            title: "Admin Rejection Successful",
            description: data?.message,
            status: "success",
            position: "top-left",
            duration: 4000,
            isClosable: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
        return;
      } else if (role === "Finance Ops") {
        setLoading(true);
        const data = await CreditRejection(id, reason);
        if (data) {
          toast({
            title: "Credit Rejection Successful",
            description: data?.message,
            status: "success",
            position: "top-left",
            duration: 4000,
            isClosable: true,
          });

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    } catch (error) {
      // toast({
      //     title: 'Rejection Error',
      //     description: error?.message,
      //     status: 'error',
      //     position: "top-left",
      //     duration: 4000,
      //     isClosable: true,
      // })
    } finally {
      setLoading(false);
      // setReason('')
      // onClose()
    }
  };
  return (
    <>
      <ModalLayout
        title="Reject Loan"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        rejectHandler={reject}
        loading={loading}
      >
        <Box>
          <Text
            fontWeight="bold"
            textAlign={"center"}
            fontSize="15px"
            color="#33343D"
            value={reason}
          >
            Are you sure you want to decline/reject this loan request with id #
            {id}
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            marginTop={"5"}
            value={reason}
          >
            Please provide reason for the rejection
          </Text>
          <Textarea
            height={"36"}
            value={reason}
            my="20px"
            onChange={(e) => setReason(e.currentTarget.value)}
            className="form-input"
          />
        </Box>
      </ModalLayout>

      {
        <Button
          onClick={() => setIsOpen(true)}
          bg="white"
          borderRadius="6px"
          fontSize={["9px", "12px"]}
          fontWeight="500"
          color="#5B2E4F"
          border="1px solid #5B2E4F"
          p={["2px 10px", "5px 37px"]}
          _hover={{
            color: "white",
            border: "1px solid #5B2E4F",
            bg: "#5B2E4F",
          }}
        >
          {loading ? "PROCESSING..." : "DECLINE"}
        </Button>
      }

      {/* <Button onClick={reject} bg="white" borderRadius="6px" fontSize={["9px", "12px"]} fontWeight="500" color="#5B2E4F" border="1px solid #5B2E4F" p={["2px 10px", "5px 37px"]} _hover={{ color: "white", border: "1px solid #5B2E4F", bg: "#5B2E4F" }} >
                DECLINE
            </Button> */}

      {/* <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader >
                        <Text color="#5B2E4F" fontWeight="700" fontFamily="satoshiBolder" fontSize="18px">Rejection Details</Text>
                        <Text fontWeight="400" fontSize="14px" color="#33343D">Fill in the following details to reject the request</Text>
                    </ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        <Box mt="20px">
                            <Text fontWeight="500" fontSize="14px" color="#33343D" value={reason}>Reason for rejection</Text>
                            <Textarea value={reason} mt="10px" onChange={(e)=>setReason(e.currentTarget.value)} className='form-input' />
                        </Box>
                    </ModalBody>

                    <ModalFooter border="1px solid #5B2E4F" mt="30px">
                        <Button fontSize="12px" border="1px solid #5B2E4F" color="#5B2E4F" mr={3} onClick={onClose}>
                            Close
                        </Button>
                        {
                            loading?
                                <Button bg="#5B2E4F" color="white" opacity={!reason ? "0.38" : '1px'} fontSize="12px" fontWeight="500" fontFamily="satoshiBold" p="13px 35px" onClick={reject} isLoading loadingText="submitting">SUBMIT</Button>
                                :
                                <Button bg="#5B2E4F" color="white" opacity={!reason ? "0.38" : '1px'} fontSize="12px" fontWeight="500" fontFamily="satoshiBold" p="13px 35px" onClick={reject} >SUBMIT</Button>
                        }
                    </ModalFooter>
                </ModalContent>
            </Modal> */}
    </>
  );
};

export default RejectionModal;
