import React, { useEffect, useMemo } from "react";
import {
  Box,
  Text,
  Flex,
  HStack,
  InputGroup,
  InputLeftElement,
  Input,
  Button,
  SimpleGrid,
} from "@chakra-ui/react";
import { BiSearch } from "react-icons/bi";
import { BiChevronDown } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import PaginatedLoans from "../components/PaginatedLoans";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import Loader from "../components/Loader/loader";
import { createMRTColumnHelper } from "material-react-table";
import moment from "moment";
import { getDeclinedLoanRequestAction } from "../redux/reducers/declinedLoanRequest";
import { useDispatch, useSelector } from "react-redux";

const Rejected = () => {
  const { declinedLoans, meta, loading } = useSelector(
    (state) => state.declinedLoanRequest
  );

  let filters = useMemo(
    () => ({
      page: 1,
      pageLimit: 50,
    }),
    []
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDeclinedLoanRequestAction(filters));
  }, [dispatch, filters]);

  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  useEffect(() => {
    if (!role) navigate("/");
    // eslint-disable-next-line
  }, []);

  const handleExportRows = (rows) => {
    const doc = new jsPDF();

    let exportedColumns = rows.map(
      ({ account_name, dob, email, phone, amount, first_name, last_name }) => ({
        account_name,
        dob,
        email,
        phone,
        amount,
        first_name,
        last_name,
      })
    );

    const tableHeaders = columns.map((c) => c.header);
    const tableData = exportedColumns.map((row) => Object.values(row));

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    let name = moment().format("LLL") + " loan.pdf";
    doc.save(name);
  };

  const searchHandler = async (e) => {
    filters = {
      ...filters,
      searchQuery: e.target.value,
    };

    dispatch(getDeclinedLoanRequestAction(filters));
  };

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("account_name", {
      header: "Account Name",
      size: 120,
    }),

    columnHelper.accessor("dob", {
      header: "DOB",
      size: 40,
    }),

    columnHelper.accessor("email", {
      header: "Email",
      size: 120,
    }),
    columnHelper.accessor("phone", {
      header: "Phone",
      size: 120,
    }),
    columnHelper.accessor("amount", {
      header: "Amount",
      size: 120,
    }),
    columnHelper.accessor("first_name", {
      header: "First Name",
      size: 120,
    }),
    columnHelper.accessor("last_name", {
      header: "Last Name",
      size: 120,
    }),
  ];

  return (
    <Box
      bg="#F8F9FC"
      p={["20px 10px", "20px 10px", "20px 10px 20px 30px"]}
      h="100vh"
      w="100%"
    >
      <Text
        color="#5B2E4F"
        fontSize="18px"
        fontWeight="500"
        fontFamily="satoshiBold"
      >
        Rejected Loans
      </Text>

      <SimpleGrid
        columns={4}
        spacing="20px"
        // minChildWidth={["100px", "70px"]}
        justify="space-between"
        mt="30px"
      >
        <HStack
          justify="space-between"
          bg="#F8F1F6"
          borderRadius="6px"
          p="20px"
        >
          <Box>
            <Text
              fontSize={["13px", "15px"]}
              color="#33343D"
              fontWeight="500"
              fontFamily="satoshiBold"
            >
              Number of Loans Rejected
            </Text>
            <Text
              fontSize="21px"
              fontFamily="satoshiBolder"
              color="#5B2E4F"
              lineHeight="28.35px"
            >
              {meta?.totalVolume || 0}
            </Text>
          </Box>
          <Box h="50px" w="50px" bg="#FFFFFF" borderRadius="4px"></Box>
        </HStack>
      </SimpleGrid>

      <Box mt="20px">
        <Text
          color="#33343D"
          fontSize="15px"
          fontWeight="700"
          fontFamily="satoshiBolder"
        >
          Loan Requests
        </Text>
        <SimpleGrid
          spacing="20px"
          columns={[2, 2, 4, 4]}
          justify="space-between"
          mt="10px"
          p={["25px 10px", "25px 15px", "25px"]}
          bg="#FFFFFF"
          maxWidth="100%"
        >
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <BiSearch size="20px" color="#666666" />
            </InputLeftElement>
            <Input
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  searchHandler(e);
                }
              }}
              border="1px solid #E4E8F2"
              fontSize="13px"
              fontFamily="satoshiBold"
              color="#666666"
              type="tel"
              placeholder="Search name or enter keyword"
            />
          </InputGroup>
          <Flex border="1px solid #5B2E4F" bg="#FFFFFF" borderRadius="7px">
            <Flex p="0 20px" justify="center" align="center">
              <Text
                fontSize="13px"
                color="#5B2E4F"
                fontWeight="500"
                fontFamily="satoshiBold"
                align="center"
              >
                All
              </Text>
            </Flex>
            <Flex
              p="0 10px"
              borderLeft="1px solid #5B2E4F"
              justify="center"
              align="center"
            >
              <BiChevronDown />
            </Flex>
          </Flex>
          <Flex border="1px solid #5B2E4F" bg="#FFFFFF" borderRadius="7px">
            <Flex p="0 15px" justify="center" align="center">
              <Text
                fontSize="13px"
                color="#5B2E4F"
                fontWeight="500"
                fontFamily="satoshiBold"
                align="center"
              >
                7days
              </Text>
            </Flex>
            <Flex
              p="0 10px"
              borderLeft="1px solid #5B2E4F"
              justify="center"
              align="center"
            >
              <BiChevronDown />
            </Flex>
          </Flex>
          <Button
            onClick={() => {
              handleExportRows(declinedLoans);
            }}
            bg="#5B2E4F"
            color="white"
            fontSize="12px"
            fontWeight="500"
            p="0 30px"
          >
            EXPORT
          </Button>
        </SimpleGrid>

        <PaginatedLoans loans={declinedLoans} meta={meta} />

        {loading && (
          <Box
            display={"flex"}
            justify={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            marginTop={"20"}
          >
            <Loader />
          </Box>
        )}

        {declinedLoans?.length === 0 && (
          <Text
            color="#5B2E4F"
            fontSize="18px"
            fontWeight="500"
            fontFamily="satoshiBold"
            textAlign={"center"}
            marginTop={"20"}
          >
            No rejected loans found
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default Rejected;
