import { Box, Flex, HStack, Input, Text, Button } from "@chakra-ui/react";
import React, { useState } from "react";
import { StandardComments, uploadAnyDocument } from "../../services/axios";

const ApprovalWorkFlow = ({ loanRequest }) => {
  const [myComment, SetMyComment] = useState();
  const [creditDocument, setCreditDocument] = useState();
  const [isUploading, SetisUploading] = useState(false);
  const [processing, setProcessing] = useState(false);

  const sendComment = async () => {
    if (!myComment) return;
    setProcessing(true);
    let res = await StandardComments(loanRequest.request_id, myComment);
    if (res.status === true) {
      window.location.reload();
    }
    setProcessing(false);
  };

  const uploadADoc = async () => {
    if (!creditDocument) return;
    SetisUploading(true);
    const formData = new FormData();
    formData.append("documents", creditDocument);
    try {
      const res = await uploadAnyDocument(loanRequest.request_id, formData);
      if (res.status === true) {
        SetisUploading(false);
        window.location.reload();
      }
    } catch (error) {
      SetisUploading(false);
    }
  };

  return (
    <>
      <Box bg="#FFFFFF" mt="20px" flexDirection={"row"} borderRadius="10px">
        <Text
          color="#540A45"
          fontWeight="700"
          fontFamily="satoshiBolder"
          p="10px 20px"
          fontSize="14px"
        >
          Approval Workflow
        </Text>

        <Flex
          w="100%"
          ml={15}
          direction={{ base: "row", md: "row" }}
          align={{ baseline: "baseline" }}
        >
          <HStack p="20px">
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
                textTransform="uppercase"
              >
                Sales Review
              </Text>
              {loanRequest?.sales_approval === "declined" ? (
                <Text
                  bg="#FFE0E0"
                  color="#FF4949"
                  p="5px 10px"
                  fontSize="10px"
                  borderRadius="3px"
                  fontWeight="500"
                  fontFamily="satoshiBold"
                  textAlign="center"
                >
                  Rejected
                </Text>
              ) : loanRequest?.sales_approval === "approved" ? (
                <Text
                  bg="#D3FDF0"
                  color="#09A775"
                  p="5px 10px"
                  fontSize="10px"
                  borderRadius="3px"
                  fontWeight="500"
                  fontFamily="satoshiBold"
                  textAlign="center"
                >
                  Approved
                </Text>
              ) : (
                <Text
                  bg="#FFF4D6"
                  color="#FF8A25"
                  p="5px 10px"
                  fontSize="10px"
                  borderRadius="3px"
                  fontWeight="500"
                  fontFamily="satoshiBold"
                  textAlign="center"
                >
                  Pending
                </Text>
              )}
            </Box>
          </HStack>

          <HStack p="20px">
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
                textTransform="uppercase"
              >
                FINANCE OPS REVIEW
              </Text>
              {loanRequest?.credit_approval === "declined" ? (
                <Text
                  bg="#FFE0E0"
                  color="#FF4949"
                  p="5px 10px"
                  fontSize="10px"
                  borderRadius="3px"
                  fontWeight="500"
                  fontFamily="satoshiBold"
                  textAlign="center"
                >
                  Rejected
                </Text>
              ) : loanRequest?.credit_approval === "approved" ? (
                <Text
                  bg="#D3FDF0"
                  color="#09A775"
                  p="5px 10px"
                  fontSize="10px"
                  borderRadius="3px"
                  fontWeight="500"
                  fontFamily="satoshiBold"
                  textAlign="center"
                >
                  Approved
                </Text>
              ) : (
                <Text
                  bg="#FFF4D6"
                  color="#FF8A25"
                  p="5px 10px"
                  fontSize="10px"
                  borderRadius="3px"
                  fontWeight="500"
                  fontFamily="satoshiBold"
                  textAlign="center"
                >
                  Pending
                </Text>
              )}
            </Box>
          </HStack>

          <HStack p="20px">
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
                textTransform="uppercase"
              >
                Finance Admin
              </Text>
              {loanRequest?.finance_approval === "declined" ? (
                <Text
                  bg="#FFE0E0"
                  color="#FF4949"
                  p="5px 10px"
                  fontSize="10px"
                  borderRadius="3px"
                  fontWeight="500"
                  fontFamily="satoshiBold"
                  textAlign="center"
                >
                  Rejected
                </Text>
              ) : loanRequest?.finance_approval === "approved" ? (
                <Text
                  bg="#D3FDF0"
                  color="#09A775"
                  p="5px 10px"
                  fontSize="10px"
                  borderRadius="3px"
                  fontWeight="500"
                  fontFamily="satoshiBold"
                  textAlign="center"
                >
                  Approved
                </Text>
              ) : (
                <Text
                  bg="#FFF4D6"
                  color="#FF8A25"
                  p="5px 10px"
                  fontSize="10px"
                  borderRadius="3px"
                  fontWeight="500"
                  fontFamily="satoshiBold"
                  textAlign="center"
                >
                  Pending
                </Text>
              )}
            </Box>
          </HStack>
        </Flex>

        <Flex
          w="100%"
          mb={5}
          ml={10}
          direction={{ base: "row", md: "row" }}
          align={{ baseline: "baseline" }}
        >
          <HStack width={"40%"}>
            <Input
              width={"100%"}
              onChange={(e) => {
                SetMyComment(e.target.value);
              }}
              border="1px solid #8c0872"
              fontSize="13px"
              fontFamily="satoshiBold"
              color="#666666"
              type="text"
              placeholder="Enter a Comment and Publish"
            />
          </HStack>

          <HStack width={"20%"} ml={5}>
            <Button
              onClick={() => {
                sendComment();
              }}
              disabled={processing}
              bg="white"
              borderRadius="6px"
              fontSize={["9px", "12px"]}
              fontWeight="500"
              color="#5B2E4F"
              border="1px solid #5B2E4F"
              p={["2px 10px", "5px 37px"]}
              _hover={{
                color: "white",
                border: "1px solid #5B2E4F",
                bg: "#5B2E4F",
              }}
            >
              {processing ? "PLEASE WAIT..." : "COMMENT"}
            </Button>
          </HStack>
        </Flex>

        <Flex
          w="50%"
          pb={5}
          ml={10}
          direction={{ base: "row", md: "row" }}
          align={{ baseline: "baseline" }}
        >
          <Input
            onChange={(e) => {
              setCreditDocument(e.target.files[0]);
            }}
            type="file"
          />

          <HStack width={"20%"} ml={5}>
            {isUploading ? (
              <Button
                disabled={isUploading}
                bg="white"
                borderRadius="6px"
                fontSize={["9px", "12px"]}
                fontWeight="500"
                color="#5B2E4F"
                border="1px solid #5B2E4F"
                p={["2px 10px", "5px 37px"]}
                _hover={{
                  color: "white",
                  border: "1px solid #5B2E4F",
                  bg: "#5B2E4F",
                }}
              >
                PLEASE WAIT...
              </Button>
            ) : (
              <Button
                onClick={() => {
                  uploadADoc();
                }}
                bg="white"
                borderRadius="6px"
                fontSize={["9px", "12px"]}
                fontWeight="500"
                color="#5B2E4F"
                border="1px solid #5B2E4F"
                p={["2px 10px", "5px 37px"]}
                _hover={{
                  color: "white",
                  border: "1px solid #5B2E4F",
                  bg: "#5B2E4F",
                }}
              >
                UPLOAD
              </Button>
            )}
          </HStack>
        </Flex>
      </Box>
    </>
  );
};

export default ApprovalWorkFlow;
