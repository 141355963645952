import React from "react";
import {
  Button,
  Modal,
  Text,
  Box,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Flex,
} from "@chakra-ui/react";
import Success from "../../assets/lottie/Success.json";
import { Player } from "@lottiefiles/react-lottie-player";

function DisbursementModalSuccess({ isOpen, setIsOpen, loanRequest }) {
  return (
    <Modal
      isCentered
      onClose={() => setIsOpen(!isOpen)}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Box>
            <Flex justify="center" align="center" direction="column" p="20px">
              <Box
                w="100%"
                h="300px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Player
                  style={{ width: "80%", height: "100%" }}
                  src={Success}
                  loop
                  autoplay
                />
              </Box>

              <Text pb="20px" fontSize="16px">
                You have Successfully Disbursed {loanRequest.first_name}{" "}
                {loanRequest.last_name}'s loan.
              </Text>

              <Button
                onClick={() => window.location.reload()}
                alignItems="center"
                bg="#5B2E4F"
                color="white"
                opacity={"1px"}
                fontSize="12px"
                fontWeight="500"
                fontFamily="satoshiBold"
                p="13px 35px"
              >
                DONE
              </Button>
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default DisbursementModalSuccess;
