import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Flex,
} from "@chakra-ui/react";
import React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
// import moment from "moment";
import { useNavigate } from "react-router-dom";
import { formatMoney } from "../services/utils/helpersFunc";

const ListofPendingDisbursementLoans = ({ allLoans }) => {
  const navigate = useNavigate();

  return (
    <Box bg="white" mt="20px">
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th
                textTransform="capitalize"
                fontSize={["10px", "12px"]}
                color="#262B40"
                lineHeight="18px"
                fontWeight="500"
              >
                {" "}
                First Name
              </Th>
              <Th
                textTransform="capitalize"
                fontSize={["10px", "12px"]}
                color="#262B40"
                lineHeight="18px"
                fontWeight="500"
              >
                Last Name
              </Th>
              <Th
                textTransform="capitalize"
                fontSize={["10px", "12px"]}
                color="#262B40"
                lineHeight="18px"
                fontWeight="500"
              >
                Email
              </Th>
              <Th
                textTransform="capitalize"
                fontSize={["10px", "12px"]}
                color="#262B40"
                lineHeight="18px"
                fontWeight="500"
              >
                Phone Number
              </Th>
              <Th
                textTransform="capitalize"
                fontSize={["10px", "12px"]}
                color="#262B40"
                lineHeight="18px"
                fontWeight="500"
              >
                Loan Amount
              </Th>
              <Th
                textTransform="capitalize"
                fontSize={["10px", "12px"]}
                color="#262B40"
                lineHeight="18px"
                fontWeight="500"
              >
                Status
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            {allLoans?.map((item) => {
              return (
                <Tr
                  cursor="pointer"
                  onClick={() =>
                    navigate(`/pending-disbursement/${item.request_id}`)
                  }
                >
                  <Td
                    fontSize={["10px", "12px"]}
                    color="#33343D"
                    lineHeight="18px"
                    fontWeight="500"
                    fontFamily="satoshiBold"
                  >
                    {item.first_name}
                  </Td>
                  <Td
                    fontSize={["10px", "12px"]}
                    color="#33343D"
                    lineHeight="18px"
                    fontWeight="500"
                    fontFamily="satoshiBold"
                  >
                    {item.last_name}
                  </Td>
                  <Td
                    fontSize={["10px", "12px"]}
                    color="#33343D"
                    lineHeight="18px"
                    fontWeight="500"
                    fontFamily="satoshiBold"
                  >
                    {item.email}
                  </Td>
                  <Td
                    fontSize={["10px", "12px"]}
                    color="#33343D"
                    lineHeight="18px"
                    fontWeight="500"
                    fontFamily="satoshiBold"
                  >
                    {item.phone}
                  </Td>
                  <Td
                    fontSize={["10px", "12px"]}
                    color="#33343D"
                    lineHeight="18px"
                    fontWeight="500"
                    fontFamily="satoshiBold"
                  >
                    {item.amount && `₦ ${formatMoney(item?.amount)}`}
                  </Td>
                  <Td>
                    {item?.admin_approval === "pending" && (
                      <Text
                        bg="#FFF4D6"
                        color="#FF8A25"
                        p="5px 10px"
                        fontSize="10px"
                        borderRadius="3px"
                        fontWeight="500"
                        fontFamily="satoshiBold"
                        textAlign="center"
                      >
                        Pending
                      </Text>
                    )}

                    {item?.admin_approval === "approved" && (
                      <Text
                        bg="#D3FDF0"
                        color="#09A775"
                        p="5px 10px"
                        fontSize="10px"
                        borderRadius="3px"
                        fontWeight="500"
                        fontFamily="satoshiBold"
                        textAlign="center"
                      >
                        Approved
                      </Text>
                    )}

                    {item?.admin_approval === "rejected" && (
                      <Text
                        bg="#FF4949"
                        color="#fff"
                        p="5px 10px"
                        fontSize="10px"
                        borderRadius="3px"
                        fontWeight="500"
                        fontFamily="satoshiBold"
                        textAlign="center"
                      >
                        Rejected
                      </Text>
                    )}

                    {!item?.admin_approval && (
                      <Text
                        bg="#FFF4D6"
                        color="#FF8A25"
                        p="5px 10px"
                        fontSize="10px"
                        borderRadius="3px"
                        fontWeight="500"
                        fontFamily="satoshiBold"
                        textAlign="center"
                      >
                        Pending
                      </Text>
                    )}
                  </Td>

                  {/* <Text
                      bg="#D3FDF0"
                      color="#09A775"
                      p="5px 10px"
                      fontSize="10px"
                      borderRadius="3px"
                      fontWeight="500"
                      fontFamily="satoshiBold"
                      textAlign="center"
                    >
                      Disbursed
                    </Text> */}
                  <Td>
                    <BsThreeDotsVertical />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex></Flex>
    </Box>
  );
};

export default ListofPendingDisbursementLoans;
