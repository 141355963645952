import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  useDisclosure,
  Button,
  Text,
  Input,
  VStack,
  Select,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FinanceApproval } from "../../services/axios";
const FinanceApproveLoanModal = ({ id }) => {
  const [proposedAmount, setProposedAmount] = useState("");
  const [monthlyInterest, setMonthlyInterest] = useState("");
  const [processingFee, setProcessingFee] = useState("");
  const [insuranceFee, setInsuranceFee] = useState("");
  const [proposedTenure, setProposedTenure] = useState("");
  const [loading, setLoading] = useState(false);

  const toast = useToast();

  const approve = async () => {
    if (!(proposedAmount && monthlyInterest && proposedTenure)) {
      return;
    }

    setLoading(true);
    const info = {
      // "finance_approval": true ,
      // "accepted": true,
      // "proposed_tenure": proposedTenure ? parseInt(proposedTenure) : 0,
      // "proposed_monthly_interest": monthlyInterest ? parseInt(monthlyInterest) : 0,
      // "proposed_amount": proposedAmount ? parseInt(proposedAmount) : 0,

      finance_approval: true,
      proposed_tenure: proposedTenure ? parseInt(proposedTenure) : 0,
      proposed_monthly_interest: monthlyInterest
        ? parseInt(monthlyInterest)
        : 0,
      proposed_amount: proposedAmount ? parseInt(proposedAmount) : 0,
      processing_fee: processingFee ? parseInt(processingFee) : 0,
      insurance_fee: insuranceFee ? parseInt(insuranceFee) : 0,
    };

    try {
      const data = await FinanceApproval(id, info);
      if (data) {
        toast({
          title: "Finance Admin Approval Successful",
          description: data?.message,
          status: "success",
          position: "top-left",
          duration: 4000,
          isClosable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000); // 1000 milliseconds = 1 second
      }
    } catch (error) {
      // toast({
      //         title: 'Finance Admin Message',
      //         description: error?.message,
      //         status: 'error',
      //         position: "top-left",
      //         duration: 4000,
      //         isClosable: true,
      //     })
    } finally {
      setLoading(false);
      //  onClose()
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        onClick={onOpen}
        bg="#5B2E4F"
        borderRadius="6px"
        fontSize="12px"
        fontWeight="500"
        color="white"
        p={["8px 22px", "5px 37px"]}
        _hover={{ color: "#5B2E4F", border: "1px solid #5B2E4F", bg: "white" }}
      >
        APPROVE
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text
              color="#5B2E4F"
              fontWeight="700"
              fontFamily="satoshiBolder"
              fontSize="18px"
            >
              Approval Loan
            </Text>
            <Text fontWeight="400" fontSize="14px" color="#33343D">
              Kindly append your signature to approve loan
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack w="100%" align="baseline" spacing={3}>
              <Box mt="20px" w="100%">
                <Text fontWeight="500" fontSize="13px" color="#33343D">
                  Proposed Amount
                </Text>
                <Input
                  type="number"
                  mt="10px"
                  value={proposedAmount}
                  onChange={(e) => setProposedAmount(e.currentTarget.value)}
                  className="form-input"
                  placeholder="Enter Amount"
                />
              </Box>
              <Box mt="20px" w="100%">
                <Text fontWeight="500" fontSize="13px" color="#33343D">
                  Proposed Tenure
                </Text>
                <Select
                  type="number"
                  mt="10px"
                  className="form-input"
                  onChange={(e) => setProposedTenure(e.currentTarget.value)}
                >
                  <option fontSize="10px" className="optionFields" value="">
                    Select An Option
                  </option>
                  {Array.from({ length: 12 }, (_, i) => i + 1).map((value) => {
                    return (
                      <option
                        key={value}
                        className="optionFields"
                        value={value}
                      >
                        {value}
                      </option>
                    );
                  })}
                </Select>
              </Box>
              {/* <Box mt="20px" w="100%">
                                <Text fontWeight="500" fontSize="13px" color="#33343D">Monthly repayment amount</Text>
                                <Input mt="10px" className='form-input' placeholder="Enter Amount" />
                            </Box> */}
              <Box mt="20px" w="100%">
                <Text fontWeight="500" fontSize="13px" color="#33343D">
                  Proposed Monthly Interest
                </Text>
                <Input
                  value={monthlyInterest}
                  onChange={(e) => setMonthlyInterest(e.currentTarget.value)}
                  type="number"
                  mt="10px"
                  className="form-input"
                  placeholder="Enter Amount"
                />
              </Box>
              <Box mt="20px" w="100%">
                <Text fontWeight="500" fontSize="13px" color="#33343D">
                  Processing Fee
                </Text>
                <Input
                  value={processingFee}
                  onChange={(e) => setProcessingFee(e.target.value)}
                  type="number"
                  mt="10px"
                  className="form-input"
                  placeholder="Enter fee"
                />
              </Box>
              <Box mt="20px" w="100%">
                <Text fontWeight="500" fontSize="13px" color="#33343D">
                  Insurance Fee
                </Text>
                <Input
                  value={insuranceFee}
                  onChange={(e) => setInsuranceFee(e.target.value)}
                  type="number"
                  mt="10px"
                  className="form-input"
                  placeholder="Enter fee"
                />
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter border="1px solid #5B2E4F" mt="30px">
            <Button
              fontSize="12px"
              border="1px solid #5B2E4F"
              color="#5B2E4F"
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
            {loading ? (
              <Button
                bg="#5B2E4F"
                color="white"
                opacity={
                  !(
                    proposedAmount &&
                    proposedTenure &&
                    monthlyInterest &&
                    insuranceFee &&
                    processingFee
                  )
                    ? "0.38"
                    : "1px"
                }
                fontSize="12px"
                fontWeight="500"
                fontFamily="satoshiBold"
                p="13px 35px"
                onClick={approve}
                isLoading
                loadingText="submitting"
              >
                SUBMIT
              </Button>
            ) : (
              <Button
                bg="#5B2E4F"
                color="white"
                opacity={
                  !(proposedAmount && proposedTenure && monthlyInterest)
                    ? "0.38"
                    : "1px"
                }
                fontSize="12px"
                fontWeight="500"
                fontFamily="satoshiBold"
                p="13px 35px"
                onClick={approve}
              >
                SUBMIT
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FinanceApproveLoanModal;
