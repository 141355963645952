import { Box, Text, Input, Flex } from "@chakra-ui/react";
import { useState } from "react";
import ModalLayout from "../Layout/ModalLayout";
import { toast } from "react-toastify";
import { formatMoney } from "../../services/utils/helpersFunc";
import { adminApproveLoanDisbursement } from "../../services/axios";

const DisburseInstantLoanApproval = ({
  loanRequest,
  isOpen,
  setIsOpen,
  setEditLoan,
  setStage,
}) => {
  const [loading, setLoading] = useState(false);
  const disburseInstantLoan = async () => {
    setLoading(true);
    const res = await adminApproveLoanDisbursement(loanRequest.request_id);
    if (res) {
      toast.success("Loan Disbursed Successfully");
      setStage("success");
    }
    setLoading(false);
  };

  return (
    <>
      <ModalLayout
        successBtnText={"DISBURSE"}
        rejectHandler={() => disburseInstantLoan()}
        cancelBtnText="CANCEL"
        title="Confirm Disbursement"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        loading={loading}
      >
        <Flex justify="space-between" scrollPaddingX="4" marginTop="5">
          <Box>
            <Text color="#540A45" fontWeight="700" fontSize="14px">
              First Name
            </Text>
            <Input
              value={loanRequest.first_name}
              disabled={true}
              className="form-input"
            />
          </Box>
          <Box>
            <Text color="#540A45" fontWeight="700" fontSize="14px">
              Last Name
            </Text>
            <Input
              value={loanRequest.last_name}
              disabled={true}
              className="form-input"
            />
          </Box>
        </Flex>
        <Flex justify="space-between" scrollPaddingX="4" marginY="5">
          <Box>
            <Text color="#540A45" fontWeight="700" fontSize="14px">
              Email
            </Text>
            <Input
              value={loanRequest.email}
              disabled={true}
              className="form-input"
            />
          </Box>

          <Box>
            <Text color="#540A45" fontWeight="700" fontSize="14px">
              Loan Amount Requested
            </Text>
            <Input
              value={`₦ ${formatMoney(loanRequest?.amount)}`}
              disabled={true}
              className="form-input"
            />
          </Box>
        </Flex>
        <Box marginY="5">
          <Text color="#540A45" fontWeight="700" fontSize="14px">
            Disbursing Amount
          </Text>
          <Input
            value={`₦ ${formatMoney(
              loanRequest?.final_amount ||
                loanRequest?.credit_recommended_amount ||
                loanRequest?.amount
            )}`}
            disabled={true}
            className="form-input"
          />
        </Box>
      </ModalLayout>
    </>
  );
};

export default DisburseInstantLoanApproval;
