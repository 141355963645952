import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRejectedLoans } from "../../services/axios";
const initialState = {
  loading: false,
  error: false,
  success: false,
  message: null,
  declinedLoans: [],
  meta: {},
};

export const getDeclinedLoanRequestAction = createAsyncThunk(
  "loanRequestAction",
  async (query) => {
    return await getRejectedLoans(query);
  }
);

//CREATE THE SLICE

export const declinedLoanRequestSlice = createSlice({
  name: "loanRequest",
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.error = false;
      state.success = false;
      state.message = "";
      state.declinedLoans = [];
      state.meta = {};
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getDeclinedLoanRequestAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDeclinedLoanRequestAction.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.declinedLoans = action.payload?.data?.data;
        state.meta = action.payload?.data?.meta;
      })
      .addCase(getDeclinedLoanRequestAction.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.message = action.payload;
        state.declinedLoans = [];
        state.meta = {};
      });
  },
});

export const { reset } = declinedLoanRequestSlice.actions;

export default declinedLoanRequestSlice.reducer;
