import axios from "axios";
import { API_BASE_URL } from "./constants/apiConstants";

// Create an instance of Axios with the desired default configuration
const api = axios.create({
  baseURL: API_BASE_URL,

  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Retrieve the token from storage

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      window.location.href = "/";
      // Network error or other unexpected error
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      // Unauthorized: redirect to login
      window.location.href = "/";
    }

    return Promise.reject(error);
  }
);

export default api;
