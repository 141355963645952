import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getLoanRequestSummary } from "../../services/axios";
const initialState = {
  loading: false,
  error: false,
  success: false,
  message: null,
  summary: {},
};

export const getLoanRequestSummaryAction = createAsyncThunk(
  "loanReduestSummary",
  async () => {
    return await getLoanRequestSummary();
  }
);

//CREATE THE SLICE

export const loanRequestSummary = createSlice({
  name: "loanRequest",
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.error = false;
      state.success = false;
      state.message = "";
      state.summary = {};
    },
  },
  //
  extraReducers: (builder) => {
    builder
      .addCase(getLoanRequestSummaryAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLoanRequestSummaryAction.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.summary = action.payload?.data;
      })
      .addCase(getLoanRequestSummaryAction.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.message = action.payload;
        state.summary = {};
      });
  },
});

export const { reset } = loanRequestSummary.actions;

export default loanRequestSummary.reducer;
