import React, { Fragment, useEffect, useState } from "react";
import { Box, Flex, HStack, Text, Button } from "@chakra-ui/react";
import NokDisplay from "../components/RequestDocument/NokDisplay";
import Documents from "../components/RequestDocument/Documents";
import {
  getInstantLoanRequestDetails,
  getUserDetails,
  getRepaymentConfig,
  getRepaymentCardDetails,
} from "../services/axios";
import { useParams } from "react-router-dom";
import TransactionLog from "../components/RequestDocument/TransactionLog";
import InstantLoanBorrowersInformation from "../components/InstantRequestDocument/InstantLoanBorrowersInformation";
import EmployerInformation from "../components/InstantRequestDocument/EmployerInformation";
import Loader from "../components/Loader/loader";
import CreditRecordScoring from "../components/RequestDocument/CreditRecordScoring";
import DeclineDisbursementModal from "../components/Modals/DeclineDisbursementModal";
import RepaymentDetails from "../components/InstantRequestDocument/RepaymentDetails";
import DisbursementModalSuccess from "../components/Modals/DisbursementSuccess";
import DisburseInstantLoanApproval from "../components/Modals/DisburseLoanApproval";

const PendingDisbursementDetails = () => {
  const { id } = useParams();
  const [loanRequest, setLoanRequest] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [repaymentConfig, setRepaymentConfig] = useState();
  const [cardDetails, setCardDetails] = useState();
  const [stage, setStage] = useState("");

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const { data } = await getInstantLoanRequestDetails(id);
      setLoading(false);
      setLoanRequest(data);

      if (data) {
        const user = await getUserDetails(data.user_id);
        setUser(user?.data);
        const config = await getRepaymentConfig(id);
        setRepaymentConfig(config.data);
        if (config.data.repayment_card_id) {
          const card = await getRepaymentCardDetails(
            config.data.repayment_card_id
          );
          setCardDetails(card.data);
        }
      }
    };
    getData();
  }, [id]);

  return (
    <Box>
      {loading && (
        <Flex justify="center" align="center" h="80vh">
          <Loader />
        </Flex>
      )}

      {!loading && loanRequest && (
        <Box bg="#F8F9FC" p={["20px 10px", "20px 10px", "20px 10px 20px 30px"]}>
          <DeclineDisbursementModal
            loanRequest={loanRequest}
            isOpen={stage === "decline" ? true : false}
            setIsOpen={setStage}
            setStage={setStage}
          />

          <DisburseInstantLoanApproval
            loanRequest={loanRequest}
            isOpen={stage === "disburse" ? true : false}
            setIsOpen={setStage}
            setStage={setStage}
          />

          <DisbursementModalSuccess
            isOpen={stage === "success" ? true : false}
            setIsOpen={setStage}
            loanRequest={loanRequest}
          />

          <Flex justify="space-between">
            <HStack
              fontSize="11px"
              p={["10px 5px", "10px"]}
              borderRadius="9px"
              fontWeight="500"
            >
              <Text
                textTransform="capitalize"
                fontSize="18px"
                color="#540A45"
                fontWeight="700"
              >
                {loanRequest?.first_name} {loanRequest?.last_name}
              </Text>
              <Text
                bg={
                  loanRequest?.admin_approval === "pending"
                    ? "#FFF4D6"
                    : loanRequest?.admin_approval === "approved"
                    ? "#D3FDF0"
                    : !loanRequest?.admin_approval
                    ? "#FFF4D6"
                    : "#FF4949"
                }
                color={
                  loanRequest?.admin_approval === "pending"
                    ? "#FF8A25"
                    : loanRequest?.admin_approval === "approved"
                    ? "#09A775"
                    : !loanRequest?.admin_approval
                    ? "FF8A25"
                    : "#fff"
                }
                paddingX="2"
                paddingY="1"
                fontWeight="extrabold"
                fontStyle={"normal"}
              >
                {loanRequest?.admin_approval || "Pending"}
              </Text>
            </HStack>

            {!loanRequest?.loan_disbursed ? (
              <Fragment>
                <HStack>
                  <Button
                    onClick={() => setStage("decline")}
                    bg="#FF4949"
                    color="white"
                    fontSize="12px"
                    fontWeight="500"
                    fontFamily="satoshiBold"
                    p="13px 35px"
                  >
                    DECLINE DISBURSEMENT
                  </Button>

                  <Button
                    onClick={() => setStage("disburse")}
                    bg="#5B2E4F"
                    color="white"
                    fontSize="12px"
                    fontWeight="500"
                    fontFamily="satoshiBold"
                    p="13px 35px"
                  >
                    DISBURSE LOAN
                  </Button>
                </HStack>
              </Fragment>
            ) : (
              ""
            )}
          </Flex>

          <RepaymentDetails
            cardDetails={cardDetails}
            repaymentConfig={repaymentConfig}
            loanRequest={loanRequest}
          />
          <CreditRecordScoring loanRequest={loanRequest} />

          <InstantLoanBorrowersInformation
            loanRequest={loanRequest}
            user={user}
          />

          <EmployerInformation loanRequest={loanRequest} />
          <NokDisplay loanRequest={loanRequest} />
          <Documents loanRequest={loanRequest} user={user} />
          <TransactionLog loanRequest={loanRequest} />
        </Box>
      )}
    </Box>
  );
};

export default PendingDisbursementDetails;
