import { Box , Text,SimpleGrid} from '@chakra-ui/react'
import React from 'react'

const BorrowerSignature = ({loanRequest}) => {
  
  return (
      <Box bg="#FFFFFF" mt="20px" borderRadius="10px">
          <Text color="#540A45" fontWeight="700" fontFamily="satoshiBolder" p="10px 20px" fontSize="14px">Borrower's Signature</Text>
          <SimpleGrid spacing="20px" minChildWidth={["100px", "70px", '50px']} p="20px" border="1px solid #E4E8F2">
              <Box>
                  <Text opacity="0.81px" fontWeight="500" fontSize="10px" color="#666666" lineHeight="18px" textTransform="uppercase">Signature</Text>
                  <a href={loanRequest.signature} target="_blank" rel="noreferrer">
                    <Text fontWeight="500" fontSize="14px" color="#5B2E4F" textDecoration="underline" lineHeight="18px" fontFamily="satoshiBold">{loanRequest.passport || '-'}</Text>
                  </a>
              </Box>
          </SimpleGrid>
    </Box>
  )
}

export default BorrowerSignature
