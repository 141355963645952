import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { Flex, HStack, Text } from "@chakra-ui/react";
import { BiChevronDown } from "react-icons/bi";
import { Select } from "@chakra-ui/react";
import ListofPendingDisbursementLoans from "./ListofPendingDisbursementLoans";

function PaginatedPendingDisbursement({
  changePage,
  setSearchQuery,
  allLoans,
  pagination,
}) {
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = allLoans.slice(itemOffset, endOffset);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % allLoans.length;
    setItemOffset(newOffset);
    setItemsPerPage(20);
    setSearchQuery({
      page: event.selected + 2,
      limit: 200,
    });

    changePage();
  };

  return (
    <>
      <ListofPendingDisbursementLoans allLoans={currentItems} />
      {allLoans?.length > 0 && (
        <Flex
          justifyContent="space-between"
          flexWrap="wrap"
          bg="#FFFFFF"
          mt="5px"
          p="10px 20px"
        >
          <HStack>
            <Select
              icon={<BiChevronDown />}
              bg="#F8F8F8"
              border="none"
              borderRadius="5px"
              color="#666666"
              opacity="0.85"
              fontWeight="700"
              fontSize="12px"
              w="80px"
              h="30px"
              onChange={(e) => {
                setItemsPerPage(e.target.value);
              }}
            >
              <option value={20}>20</option>
              {allLoans.length > 20 && <option value={40}>40</option>}
              {allLoans.length > 40 && <option value={60}>60</option>}
              {allLoans.length > 60 && <option value={80}>80</option>}
              {allLoans.length > 80 && <option value={80}>100</option>}
              {allLoans.length > 100 && <option value={100}>120</option>}
            </Select>

            <Text
              color="#666666"
              opacity="0.85"
              fontWeight="700"
              fontSize="12px"
            >
              Showing 1-{itemsPerPage} of {allLoans?.length} Enteries
            </Text>
          </HStack>

          {pagination.hasNext && (
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              pageCount={Math.ceil(Number(allLoans.length) / itemsPerPage)}
              previousLabel="Prev"
              previousClassName="previousClassName"
              nextClassName="nextClassName"
              breakClassName="breakClassName"
              pageLinkClassName="pageLinkClassName"
              pageClassName="pageClassName"
              containerClassName="containerClassName"
              activeLinkClassName="activeLinkClassName"
              disabledClassName="disabledClassName"
              renderOnZeroPageCount={null}
            />
          )}
        </Flex>
      )}
    </>
  );
}

export default PaginatedPendingDisbursement;
