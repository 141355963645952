import React, { useRef } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
  Img,
  Box,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import MenuLinks from "./MenuLinks";
import logo from "../../assets/Hervest-logo.svg";

const DrawerMenuSidebar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  return (
    <>
      <Button
        ref={btnRef}
        zIndex="20"
        onClick={onOpen}
        display={["flex", "flex", "none"]}
      >
        <HamburgerIcon />
      </Button>
      <Box bg="#FFFFFF">
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          isFullHeight
          finalFocusRef={btnRef}
        >
          <DrawerContent>
            <DrawerCloseButton color="#33343D" />
            <DrawerHeader>
              <Img src={logo} objectFit="contain" w="40%" />
            </DrawerHeader>
            <DrawerBody m="0" p="0">
              <MenuLinks />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
    </>
  );
};

export default DrawerMenuSidebar;
