import { Box, Text, SimpleGrid } from "@chakra-ui/react";
import React from "react";
// import moment from "moment";

const NokDisplay = ({ loanRequest }) => {
  return (
    <Box bg="#FFFFFF" mt="20px" borderRadius="10px">
      <Text
        color="#540A45"
        fontWeight="700"
        fontFamily="satoshiBolder"
        p="10px 20px"
        fontSize="14px"
      >
        Next of Kin Information
      </Text>
      <SimpleGrid
        spacing="20px"
        minChildWidth={["100px", "70px", "50px"]}
        p="20px"
        border="1px solid #E4E8F2"
      >
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            FULL NAME
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest.nok_name || "-"}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            PHONE NUMBER
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest.nok_phone || "-"}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            DATE OF BIRTH
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {/* {moment(loanRequest.nok_dob).format("Do MMMM YYYY") || "-"} */}
            {loanRequest?.nok_dob}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
            textTransform="uppercase"
          >
            place of work
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest.nok_place_of_work || "-"}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            OCCUPATION
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest.occupation || "-"}
          </Text>
        </Box>
      </SimpleGrid>
      <SimpleGrid
        spacing="20px"
        minChildWidth={["100px", "70px", "50px"]}
        p="20px"
        border="1px solid #E4E8F2"
      >
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            EMAIL ADDRESS
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest.nok_email || "-"}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            office number
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest.nok_office_phone || "-"}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
            textTransform="uppercase"
          >
            office address
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest.nok_office_address || "-"}
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default NokDisplay;
