import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Divider,
} from "@chakra-ui/react";

function ModalLayout({
  children,
  rejectHandler,
  title,
  loading,
  isOpen,
  setIsOpen,
  cancelBtnText,
  successBtnText,
  backHandler,
}) {
  return (
    <>
      <Modal
        isCentered
        onClose={() => setIsOpen(!isOpen)}
        isOpen={isOpen}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{children}</ModalBody>
          <Divider bg="#5B2E4F" opacity={"1px"} height="1px" width="100%" />
          <ModalFooter>
            <Button
              fontSize="12px"
              border="1px solid #5B2E4F"
              color="#5B2E4F"
              mr={3}
              onClick={backHandler ? backHandler : () => setIsOpen(!isOpen)}
            >
              {cancelBtnText ? cancelBtnText : "CLOSE"}
            </Button>
            <Button
              disabled={loading}
              onClick={rejectHandler}
              bg="#5B2E4F"
              color="white"
              opacity={"1px"}
              fontSize="12px"
              fontWeight="500"
              fontFamily="satoshiBold"
              p="13px 35px"
            >
              {loading
                ? "PROCESSING..."
                : successBtnText
                ? successBtnText
                : "CONFIRM"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalLayout;
