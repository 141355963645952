import { Box, Text, SimpleGrid } from "@chakra-ui/react";
import React from "react";

const Documents = ({ loanRequest, user }) => {
  return (
    <Box bg="#FFFFFF" mt="20px" borderRadius="10px">
      <Text
        color="#540A45"
        fontWeight="700"
        fontFamily="satoshiBolder"
        p="10px 20px"
        fontSize="14px"
      >
        Documents
      </Text>
      <SimpleGrid
        spacing="20px"
        minChildWidth={["100px", "70px", "50px"]}
        p="20px"
        border="1px solid #E4E8F2"
      >
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
            textTransform="uppercase"
          >
            passport photograph
          </Text>
          <a
            href={user?.face_image || loanRequest.passport}
            target="_blank"
            rel="noreferrer"
          >
            <Text
              fontWeight="500"
              fontSize="14px"
              color="#5B2E4F"
              textDecoration="underline"
              lineHeight="18px"
              fontFamily="satoshiBold"
            >
              {user?.face_image || loanRequest.passport || "-"}
            </Text>
          </a>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
            textTransform="uppercase"
          >
            government id
          </Text>
          <a
            href={user?.document_image || loanRequest?.government_id}
            target="_blank"
            rel="noreferrer"
          >
            <Text
              fontWeight="500"
              fontSize="14px"
              color="#5B2E4F"
              lineHeight="18px"
              fontFamily="satoshiBold"
              textDecoration="underline"
            >
              {user?.document_image || loanRequest?.government_id || "-"}
            </Text>
          </a>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
            textTransform="uppercase"
          >
            Utility Bill
          </Text>
          <a href={loanRequest.utility_bill} target="_blank" rel="noreferrer">
            <Text
              fontWeight="500"
              fontSize="14px"
              color="#5B2E4F"
              lineHeight="18px"
              fontFamily="satoshiBold"
              textDecoration="underline"
            >
              {loanRequest.utility_bill || "-"}
            </Text>
          </a>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
            textTransform="uppercase"
          >
            bank statement
          </Text>
          <a href={loanRequest.bank_statement} target="_blank" rel="noreferrer">
            <Text
              fontWeight="500"
              fontSize="14px"
              color="#5B2E4F"
              lineHeight="18px"
              fontFamily="satoshiBold"
              textDecoration="underline"
            >
              {loanRequest.bank_statement || "-"}
            </Text>
          </a>
        </Box>
        {loanRequest?.cac && (
          <Box>
            <Text
              opacity="0.81px"
              fontWeight="500"
              fontSize="10px"
              color="#666666"
              lineHeight="18px"
              textTransform="uppercase"
            >
              cac
            </Text>
            <a href={loanRequest.cac} target="_blank" rel="noreferrer">
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#5B2E4F"
                lineHeight="18px"
                fontFamily="satoshiBold"
                textDecoration="underline"
              >
                {loanRequest.cac || "-"}
              </Text>
            </a>
          </Box>
        )}
      </SimpleGrid>
    </Box>
  );
};

export default Documents;
