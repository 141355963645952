import React, { useEffect, useState } from "react";
import { Text, Flex, HStack } from "@chakra-ui/react";

import { BsBell } from "react-icons/bs";
// import { AiFillCaretDown } from 'react-icons/ai'
import DrawerMenuSidebar from "./Sidebar/DrawerMenuSidebar";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const role = localStorage.getItem("role");

  const [name] = useState(localStorage.getItem("name")?.split(/(\s+)/));

  const navigate = useNavigate();
  useEffect(
    () => {
      if (!role) navigate("/");
      // eslint-disable-next-line
    },

    [navigate, role]
    // []
  );

  return (
    <Flex
      justifyContent="space-between"
      w="100%"
      top="0"
      p={["10px", "10px 30px"]}
      bg="white"
      borderBottom="0.17px solid #5B2E4F"
    >
      <DrawerMenuSidebar />
      <HStack spacing={4} w="100%" justify="end" pos="relative">
        <BsBell color="#666666" />
        <HStack spacing={0}>
          <Text m={2}>
            {" "}
            {name[0][0]} {name[2] === undefined ? "" : name[2][0]}{" "}
          </Text>
          {/* <Avatar size="sm" color='#666666'/> */}
          <Text color="#33343D" fontWeight="500" fontSize={[12, 14]}>
            {role}
          </Text>
          {/* <AiFillCaretDown color='#666666'/> */}
        </HStack>
      </HStack>
    </Flex>
  );
};

export default NavBar;
