import React, { Fragment, useEffect, useState } from "react";
import { Box, Flex, HStack, Text, Button } from "@chakra-ui/react";
import NokDisplay from "../components/RequestDocument/NokDisplay";
import Documents from "../components/RequestDocument/Documents";
import {
  getInstantLoanRequestDetails,
  getUserDetails,
} from "../services/axios";
import { useParams } from "react-router-dom";
import TransactionLog from "../components/RequestDocument/TransactionLog";
import InstantLoanBorrowersInformation from "../components/InstantRequestDocument/InstantLoanBorrowersInformation";
import EmployerInformation from "../components/InstantRequestDocument/EmployerInformation";
import EditLoanModal from "../components/Modals/EditLoanModal";
import ConfirmationModal from "../components/Modals/ConfirmationModal";
import RejectInstantLoan from "../components/Modals/RejectInstantLoan";
import SuccessModal from "../components/Modals/SuccessModal";
import Loader from "../components/Loader/loader";
import ConfirmInstantLoanApproval from "../components/Modals/ConfirmInstantLoanApproval";
import CreditRecordScoring from "../components/RequestDocument/CreditRecordScoring";
import { formatMoney } from "../services/utils/helpersFunc";
import { commentLogs } from "../services/axios";
import LogLoanModal from "../components/RequestDocument/LogLoanModal";

import InstantLoanApprovalWorkFlow from "../components/RequestDocument/InstantLoanApprovalWorkFlow";
const InstantLoanDetails = () => {
  const { id } = useParams();
  const [loanRequest, setLoanRequest] = useState([]);
  const [editLoan, setEditLoan] = useState(0);
  const [rejectLoan, setRejectLoan] = useState(false);
  const [newAmount, setNewAmount] = useState();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [limit, setLimit] = useState(10);
  const [processing, setProcessing] = useState(false);
  const [loanLogs, setLoanLogs] = useState([]);

  const getLogs = async (theLimit) => {
    setProcessing(true);
    const { data } = await commentLogs(id, theLimit);
    if (data) {
      setLoanLogs(data?.data);
    }
    setProcessing(false);
    return;
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const { data } = await getInstantLoanRequestDetails(id);
      setLoading(false);
      setLoanRequest(data);

      if (data) {
        const user = await getUserDetails(data.user_id);
        setUser(user?.data);
      }
    };
    getData();
    getLogs(limit);
    // eslint-disable-next-line
  }, [id]);

  return (
    <Box>
      {loading && (
        <Flex justify="center" align="center" h="80vh">
          <Loader />
        </Flex>
      )}

      {!loading && loanRequest && (
        <Box bg="#F8F9FC" p={["20px 10px", "20px 10px", "20px 10px 20px 30px"]}>
          <EditLoanModal
            loanRequest={loanRequest}
            isOpen={editLoan === 1 ? true : false}
            setIsOpen={setEditLoan}
            setNewAmount={setNewAmount}
            newAmount={newAmount}
          />

          <RejectInstantLoan
            loanRequest={loanRequest}
            isOpen={rejectLoan}
            setIsOpen={setRejectLoan}
            setEditLoan={setEditLoan}
          />

          {newAmount && (
            <ConfirmationModal
              loanRequest={loanRequest}
              isOpen={editLoan === 2 ? true : false}
              setIsOpen={setEditLoan}
              newAmount={newAmount}
            />
          )}

          <SuccessModal
            isOpen={editLoan === 3 ? true : false}
            setIsOpen={setEditLoan}
            loanRequest={loanRequest}
          />

          <ConfirmInstantLoanApproval
            loanRequest={loanRequest}
            isOpen={editLoan === 4 ? true : false}
            setIsOpen={setEditLoan}
            setEditLoan={setEditLoan}
          />

          <Flex justify="space-between" alignItems={"center"}>
            <HStack
              fontSize="11px"
              p={["10px 5px", "10px"]}
              borderRadius="9px"
              fontWeight="500"
            >
              <Text
                textTransform="capitalize"
                fontSize="18px"
                color="#540A45"
                fontWeight="700"
              >
                {loanRequest?.first_name} {loanRequest?.last_name}
              </Text>
              <Text
                bg={
                  loanRequest?.admin_approval === "pending"
                    ? "#FFF4D6"
                    : loanRequest?.admin_approval === "approved"
                    ? "#D3FDF0"
                    : !loanRequest?.admin_approval
                    ? "#FFF4D6"
                    : "#FF4949"
                }
                color={
                  loanRequest?.admin_approval === "pending"
                    ? "#FF8A25"
                    : loanRequest?.admin_approval === "approved"
                    ? "#09A775"
                    : !loanRequest?.admin_approval
                    ? "FF8A25"
                    : "#fff"
                }
                paddingX="2"
                paddingY="1"
                fontWeight="extrabold"
                fontStyle={"normal"}
              >
                {loanRequest?.admin_approval || "Pending"}
              </Text>
            </HStack>

            {!loanRequest?.admin_approval ||
            loanRequest.admin_approval === "pending" ? (
              <Fragment>
                <HStack>
                  <Button
                    onClick={() => setEditLoan(4)}
                    bg="#5B2E4F"
                    color="white"
                    fontSize="12px"
                    fontWeight="500"
                    fontFamily="satoshiBold"
                    p="13px 35px"
                  >
                    APPROVE
                  </Button>

                  <Button
                    onClick={() => setEditLoan(1)}
                    bg="white"
                    borderRadius="6px"
                    fontSize={["9px", "12px"]}
                    fontWeight="500"
                    color="#5B2E4F"
                    border="1px solid #5B2E4F"
                    p={["2px 10px", "5px 37px"]}
                    _hover={{
                      color: "white",
                      border: "1px solid #5B2E4F",
                      bg: "#5B2E4F",
                    }}
                  >
                    EDIT
                  </Button>

                  <Button
                    onClick={() => setRejectLoan(true)}
                    bg="#FF4949"
                    color="white"
                    fontSize="12px"
                    fontWeight="500"
                    fontFamily="satoshiBold"
                    p="13px 35px"
                  >
                    REJECT
                  </Button>
                </HStack>
              </Fragment>
            ) : (
              <>
                {loanRequest.admin_approval === "approved" && (
                  <Text
                    fontWeight="900"
                    fontSize="18px"
                    color="#33343D"
                    lineHeight="18px"
                    fontFamily="satoshiBold"
                  >
                    APPROVED: ₦
                    {formatMoney(
                      Number(
                        loanRequest?.final_amount ||
                          loanRequest?.credit_recommended_amount ||
                          loanRequest?.amount
                      )
                    )}
                  </Text>
                )}
              </>
            )}
          </Flex>

          <InstantLoanBorrowersInformation
            loanRequest={loanRequest}
            user={user}
          />

          <CreditRecordScoring loanRequest={loanRequest} />

          <EmployerInformation loanRequest={loanRequest} />
          <NokDisplay loanRequest={loanRequest} />
          <Documents loanRequest={loanRequest} user={user} />
          <InstantLoanApprovalWorkFlow loanRequest={loanRequest} />
          <LogLoanModal
            processing={processing}
            getLogs={getLogs}
            loanLogs={loanLogs}
            limit={limit}
            setLimit={setLimit}
          />
          <TransactionLog loanRequest={loanRequest} />
        </Box>
      )}
    </Box>
  );
};

export default InstantLoanDetails;
