import React, { useEffect, useState } from "react";
import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { BiChevronLeft } from "react-icons/bi";
import NokDisplay from "../components/RequestDocument/NokDisplay";
import Documents from "../components/RequestDocument/Documents";
import FinanceApproval from "../components/RequestDocument/FinanceApproval";
import ApprovalWorkFlow from "../components/RequestDocument/ApprovalWorkFlow";
import {
  commentLogs,
  getALoanRequest,
  getLoanRequestSLA,
} from "../services/axios";
import GuarantorInformation from "../components/RequestDocument/GuarantorInformation";

import { Link, useParams } from "react-router-dom";
import ApproveLoanModal from "../components/Modals/ApproveLoanModal";
import RejectionModal from "../components/Modals/RejectionModal";
import BorrowersInformation from "../components/RequestDocument/BorrowersInformation";
import CreditApprovalModal from "../components/Modals/CreditApprovalModal";
import FinanceApproveLoanModal from "../components/Modals/FinanceApproveLoanModal";
import BorrowerSignature from "../components/RequestDocument/BorrowerSignature";
import LogLoanModal from "../components/RequestDocument/LogLoanModal";
import Loader from "../components/Loader/loader";
import RequestSLA from "../components/RequestSLA";

const RequestPage = () => {
  const { id } = useParams();

  //get the rejected from the url search param
  const isRejectedLoans = sessionStorage.getItem("rejected");

  const role = localStorage.getItem("role");

  const [loanRequest, setLoanRequest] = useState([]);
  const [loanLogs, setLoanLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [processing, setProcessing] = useState(false);
  const [loanRequestSLA, setLoanRequestSLA] = useState();

  const getLogs = async (theLimit) => {
    setProcessing(true);
    const { data } = await commentLogs(id, theLimit);
    if (data) {
      setLoanLogs(data?.data);
    }
    setProcessing(false);
    return;
  };

  const loadLoanRequestSLA = async () => {
    const { data } = await getLoanRequestSLA(id);
    console.log(data, "THE SLA BACKKK");
    setLoanRequestSLA(data);
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const { data } = await getALoanRequest(id);
      setLoanRequest(data);
      setLoading(false);
    };
    getData();
    getLogs(limit);
    loadLoanRequestSLA();

    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {loading && (
        <Flex justify="center" align="center" h="80vh">
          <Loader />
        </Flex>
      )}

      {!loading && loanRequest && (
        <Box bg="#F8F9FC" p={["20px 10px", "20px 10px", "20px 10px 20px 30px"]}>
          <Flex justify="space-between">
            <Link to="/dashboard">
              <HStack
                color="#33343D"
                fontSize="11px"
                bg="#F8F1F6"
                p={["10px 5px", "10px"]}
                borderRadius="9px"
                fontWeight="500"
              >
                <BiChevronLeft color="#33343D" fontSize="17" />
                <Text>Back to requests</Text>
              </HStack>
            </Link>
            <RequestSLA loanRequestSLA={loanRequestSLA} />
            {/* <Countdown
              date={Date.now() + 50000000}
              style={{ fontSize: "20px", color: "#33343D", fontWeight: "500" }}
            /> */}
            ,
            <HStack>
              {!isRejectedLoans && (
                <>
                  {/* {role !== "Finance Ops" && <RejectionModal id={id} />} */}
                  <RejectionModal id={id} />
                  {/* {role === "Sales Ops" && <></>} */}

                  {role === "Finance Ops" ? (
                    <CreditApprovalModal id={id} />
                  ) : role === "Finance Admin" ? (
                    <FinanceApproveLoanModal id={id} />
                  ) : (
                    <ApproveLoanModal id={id} />
                  )}
                </>
              )}
            </HStack>
          </Flex>

          <BorrowersInformation loanRequest={loanRequest} />
          <NokDisplay loanRequest={loanRequest} />
          <GuarantorInformation id={id} />
          <Documents loanRequest={loanRequest} />
          <BorrowerSignature loanRequest={loanRequest} />
          <FinanceApproval loanRequest={loanRequest} />
          <ApprovalWorkFlow loanRequest={loanRequest} />
          <LogLoanModal
            processing={processing}
            getLogs={getLogs}
            loanLogs={loanLogs}
            limit={limit}
            setLimit={setLimit}
          />
        </Box>
      )}
    </>
  );
};

export default RequestPage;
