import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getLoanRequests } from "../../services/axios";
const initialState = {
  loading: false,
  error: false,
  success: false,
  message: null,
  loans: [],
  meta: {},
};

export const getLoanRequestAction = createAsyncThunk(
  "loanRequestAction",
  async (query) => {
    return await getLoanRequests(query);
  }
);

//CREATE THE SLICE

export const loanRequestSlice = createSlice({
  name: "loanRequest",
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.error = false;
      state.success = false;
      state.message = "";
      state.loans = [];
      state.meta = {};
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getLoanRequestAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLoanRequestAction.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.loans = action.payload?.data?.data;
        state.meta = action.payload?.data?.meta;
      })
      .addCase(getLoanRequestAction.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.message = action.payload;
        state.loans = [];
      });
  },
});

export const { reset } = loanRequestSlice.actions;

export default loanRequestSlice.reducer;
