import React from "react";
import { motion } from "framer-motion";

function Loader() {
  return (
    <motion.div
      initial={{ scale: 0.8 }}
      animate={{ scale: 1.2 }}
      exit={{ scale: 0.8 }}
      transition={{
        repeat: Infinity,
        duration: 1,
        ease: "easeInOut",
        repeatType: "reverse",
      }}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span className="loader"></span>
    </motion.div>
  );
}

export default Loader;
