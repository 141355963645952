import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { Flex, HStack, Text } from "@chakra-ui/react";
import { BiChevronDown } from "react-icons/bi";
import ListofInstantLoans from "./ListofInstantLoans";
import { Select } from "@chakra-ui/react";

function PaginatedInstantLoan({
  changePage,
  setSearchQuery,
  instantLoans,
  meta,
}) {
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const handlePageClick = (event) => {
    setItemsPerPage(20);
    setSearchQuery({
      page: event.selected + 2,
      limit: 200,
    });

    changePage();
  };

  return (
    <>
      <ListofInstantLoans instantLoans={instantLoans} />
      {instantLoans?.length > 0 && (
        <Flex
          justifyContent="space-between"
          flexWrap="wrap"
          bg="#FFFFFF"
          mt="5px"
          p="10px 20px"
        >
          <HStack>
            <Select
              icon={<BiChevronDown />}
              bg="#F8F8F8"
              border="none"
              borderRadius="5px"
              color="#666666"
              opacity="0.85"
              fontWeight="700"
              fontSize="12px"
              w="80px"
              h="30px"
              onChange={(e) => {
                setItemsPerPage(e.target.value);
              }}
            >
              <option value={10}>10</option>
              {instantLoans.length > 20 && <option value={40}>20</option>}
              {instantLoans.length > 30 && <option value={60}>30</option>}
              {instantLoans.length > 40 && <option value={80}>40</option>}
              {instantLoans.length > 50 && <option value={80}>50</option>}
            </Select>

            <Text
              color="#666666"
              opacity="0.85"
              fontWeight="700"
              fontSize="12px"
            >
              Showing 1-{itemsPerPage} of {instantLoans?.length} Enteries
            </Text>
          </HStack>

          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            onPageChange={(event) => handlePageClick(event)}
            pageRangeDisplayed={2}
            pageCount={meta?.totalPages}
            previousLabel="Prev"
            previousClassName="previousClassName"
            nextClassName="nextClassName"
            breakClassName="breakClassName"
            pageLinkClassName="pageLinkClassName"
            pageClassName="pageClassName"
            containerClassName="containerClassName"
            activeLinkClassName="activeLinkClassName"
            disabledClassName="disabledClassName"
            renderOnZeroPageCount={null}
          />
        </Flex>
      )}
    </>
  );
}

export default PaginatedInstantLoan;
