import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { persistStore } from "redux-persist";
import storage from "redux-persist/es/storage";
import loanRequestReducer from "../reducers/loanRequestReducer";
import loanSummaryReducer from "../reducers/loanSummaryReducer";
import declinedLoanRequestReducer from "../reducers/declinedLoanRequest";
import instantLoanRequestReducer from "../reducers/instantLoanRequestReducer";
export const rootReducer = combineReducers({
  loanRequest: loanRequestReducer,
  loanSummary: loanSummaryReducer,
  declinedLoanRequest: declinedLoanRequestReducer,
  instantLoanRequest: instantLoanRequestReducer,
});

const config = {
  key: "hervest-loan-admin-reducers",
  storage,
};

const persistedRootReducer = persistReducer(config, rootReducer);

// MiddleWare Implementation and State Management
export const store = configureStore({
  reducer: persistedRootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

//State Persistence for the entire state
export const persiststore = persistStore(store);
