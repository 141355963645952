import { Box, Text, Input, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import ModalLayout from "../Layout/ModalLayout";
import { toast } from "react-toastify";
import { formatMoney } from "../../services/utils/helpersFunc";

const EditLoanModal = ({
  loanRequest,
  isOpen,
  setIsOpen,
  setNewAmount,
  newAmount,
}) => {
  const nextHandler = () => {
    if (!newAmount) {
      toast.error("Please input a new amount");
      return;
    }
    if (newAmount === loanRequest.amount) {
      toast.error("Please input a different amount");
      return;
    }
    setIsOpen(2);
  };

  const [displayAmount, setDisplayAmount] = useState();
  return (
    <>
      <ModalLayout
        rejectHandler={() => nextHandler()}
        cancelBtnText="CANCEL"
        title="Edit Loan Recommendation"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        loading={false}
      >
        <Flex justify="space-between" scrollPaddingX="4" marginTop="5">
          <Box>
            <Text color="#540A45" fontWeight="700" fontSize="14px">
              First Name
            </Text>
            <Input
              value={loanRequest.first_name}
              disabled={true}
              className="form-input"
            />
          </Box>
          <Box>
            <Text color="#540A45" fontWeight="700" fontSize="14px">
              Last Name
            </Text>
            <Input
              value={loanRequest.last_name}
              disabled={true}
              className="form-input"
            />
          </Box>
        </Flex>

        <Flex justify="space-between" scrollPaddingX="4" marginY="5">
          <Box>
            <Text color="#540A45" fontWeight="700" fontSize="14px">
              Email
            </Text>
            <Input
              value={loanRequest.email}
              disabled={true}
              className="form-input"
            />
          </Box>

          <Box>
            <Text color="#540A45" fontWeight="700" fontSize="14px">
              Loan Amount Requested
            </Text>
            <Input
              value={`₦ ${formatMoney(loanRequest.amount)}`}
              disabled={true}
              className="form-input"
            />
          </Box>
        </Flex>

        <Box marginY="5">
          <Text color="#540A45" fontWeight="700" fontSize="14px">
            Credit System Receommendation
          </Text>
          <Input
            value={loanRequest.credit_recommended_amount}
            disabled={true}
            className="form-input"
          />
        </Box>
        <Box marginY="5">
          <Text color="#540A45" fontWeight="700" fontSize="14px">
            New Recommended Amount
          </Text>
          <Input
            onChange={(e) => {
              setNewAmount(e.target.value);
              setDisplayAmount(e.target.value);
            }}
            value={displayAmount}
            className="form-input"
          />

          {displayAmount && (
            <Text
              color="#540A45"
              fontWeight={800}
              fontSize="12px"
              opacity="0.8"
            >
              ₦{formatMoney(Number(displayAmount))}
            </Text>
          )}
        </Box>
      </ModalLayout>
    </>
  );
};

export default EditLoanModal;
