import { Box, Text, Input, Flex } from "@chakra-ui/react";
import { useState } from "react";
import ModalLayout from "../Layout/ModalLayout";
import { toast } from "react-toastify";
import { adminApproveInstantLoan } from "../../services/axios";
import { formatMoney } from "../../services/utils/helpersFunc";

const ConfirmInstantLoanApproval = ({
  loanRequest,
  isOpen,
  setIsOpen,
  setEditLoan,
}) => {
  const [loading, setLoading] = useState(false);
  const approveInstantLoan = async () => {
    setLoading(true);
    const res = await adminApproveInstantLoan(loanRequest.request_id);
    if (res) {
      toast.success("Loan Approved Successfully");
      setEditLoan(3);
    }
    setLoading(false);
  };

  return (
    <>
      <ModalLayout
        rejectHandler={() => approveInstantLoan()}
        cancelBtnText="CANCEL"
        title="Approve Loan Recommendation"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        loading={loading}
      >
        <Flex justify="space-between" scrollPaddingX="4" marginTop="5">
          <Box>
            <Text color="#540A45" fontWeight="700" fontSize="14px">
              First Name
            </Text>
            <Input
              value={loanRequest.first_name}
              disabled={true}
              className="form-input"
            />
          </Box>
          <Box>
            <Text color="#540A45" fontWeight="700" fontSize="14px">
              Last Name
            </Text>
            <Input
              value={loanRequest.last_name}
              disabled={true}
              className="form-input"
            />
          </Box>
        </Flex>
        <Flex justify="space-between" scrollPaddingX="4" marginY="5">
          <Box>
            <Text color="#540A45" fontWeight="700" fontSize="14px">
              Email
            </Text>
            <Input
              value={loanRequest.email}
              disabled={true}
              className="form-input"
            />
          </Box>

          <Box>
            <Text color="#540A45" fontWeight="700" fontSize="14px">
              Loan Amount Requested
            </Text>
            <Input
              value={`₦ ${formatMoney(loanRequest?.amount)}`}
              disabled={true}
              className="form-input"
            />
          </Box>
        </Flex>
        <Box marginY="5">
          <Text color="#540A45" fontWeight="700" fontSize="14px">
            Credit System Receommendation
          </Text>
          <Input
            value={formatMoney(loanRequest?.credit_recommended_amount)}
            disabled={true}
            className="form-input"
          />
        </Box>

        {loanRequest?.final_amount && (
          <Box marginY="5">
            <Text color="#540A45" fontWeight="700" fontSize="14px">
              Final Approval Amount
            </Text>
            <Input
              value={formatMoney(loanRequest.final_amount)}
              disabled={true}
              className="form-input"
            />
          </Box>
        )}
      </ModalLayout>
    </>
  );
};

export default ConfirmInstantLoanApproval;
