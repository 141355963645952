import React, { Fragment } from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import { GoArrowDownLeft, GoArrowUpRight } from "react-icons/go";

const TransactionLog = ({ loanRequest }) => {
  return (
    <Box bg="#FFFFFF" mt="20px" borderRadius="10px" p="10px 20px">
      <Text
        color="#540A45"
        fontWeight="700"
        fontFamily="satoshiBolder"
        fontSize="14px"
      >
        Transaction Log
      </Text>

      {loanRequest?.transactions?.map((transaction, index) => (
        <Fragment>
          <Flex marginY="5">
            <GoArrowDownLeft
              size={30}
              color="#44FF22"
              style={{
                color: "#1BB100",
                fontSize: "20px",
                marginTop: "5px",
                marginRight: "5px",
                backgroundColor: "#44FF22",
                padding: "5px",
                borderRadius: "50px",
              }}
            />
            <Box fontSize="14px" paddingLeft="10px">
              <Text>
                Credit - N5,000 loan disbursement to David Olatoyin- HerVest
                purse
              </Text>
              <Text>ref: QWERTY1234 21st Feb 2024 15:10PM</Text>
            </Box>
          </Flex>

          <Flex marginY="5">
            <GoArrowUpRight
              size={30}
              color="#B40E78"
              style={{
                color: "#B40E78",
                fontSize: "20px",
                marginTop: "5px",
                marginRight: "5px",
                backgroundColor: "#F37A9E",
                padding: "5px",
                borderRadius: "50px",
              }}
            />
            <Box fontSize="14px" paddingLeft="10px">
              <Text>
                Debit - N2,000 loan repayment from David Olatoyin- HerVest purse
              </Text>
              <Text>ref: QWERTY1234 21st Feb 2024 15:10PM</Text>
            </Box>
          </Flex>
        </Fragment>
      ))}

      {!loanRequest?.transactions && (
        <Text marginY={5}>No transaction log available</Text>
      )}
    </Box>
  );
};

export default TransactionLog;
