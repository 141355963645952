import { Text, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import ModalLayout from "../Layout/ModalLayout";
import { adminEditInstantLoan } from "../../services/axios";
import { toast } from "react-toastify";
import { formatMoney } from "../../services/utils/helpersFunc";

const ConfirmationModal = ({ loanRequest, isOpen, setIsOpen, newAmount }) => {
  const [loading, setLoading] = useState(false);
  const editLoanAmount = async () => {
    setLoading(true);
    try {
      await adminEditInstantLoan(loanRequest.request_id, newAmount);
      setIsOpen(3);
      setLoading(false);
      toast.success("Loan updated successfully");
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <ModalLayout
        rejectHandler={editLoanAmount}
        backHandler={() => setIsOpen(1)}
        cancelBtnText="BACK"
        title="Confirmation"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        loading={loading}
      >
        <Text marginBottom="5" color="#540A45" fontWeight="700" fontSize="14px">
          Are you sure you want to make these changes?
        </Text>

        <Flex marginY="2" color="#540A45">
          <Text> Loan amount requested: </Text>
          <Text fontWeight="700"> ₦ {formatMoney(loanRequest?.amount)}</Text>
        </Flex>

        <Flex color="#540A45">
          <Text> Credit system recommendation: </Text>
          <Text fontWeight="700">
            {loanRequest?.credit_recommended_amount &&
              `₦ ${formatMoney(loanRequest?.credit_recommended_amount)}`}
          </Text>
        </Flex>

        <Flex marginTop="2" color="#540A45" marginBottom="5">
          <Text> New Loan Amount recommendation: </Text>
          <Text fontWeight="700"> ₦ {formatMoney(newAmount)}</Text>
        </Flex>
      </ModalLayout>
    </>
  );
};

export default ConfirmationModal;
