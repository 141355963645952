import {
  // Modal,
  // ModalOverlay,
  // ModalContent,
  // ModalHeader,
  // ModalFooter,
  // ModalBody,
  // ModalCloseButton,
  // Box, Text, Input, Textarea,
  // useDisclosure,
  Button,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { CreditApproval } from "../../services/axios";

const CreditApprovalModal = ({ id }) => {
  // const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast();

  // const [comment, setComment] = useState('')
  // const [creditDocument, setCreditDocument] = useState('')

  const [loading, setLoading] = useState(false);

  const approve = async () => {
    // if (!(comment && creditDocument)) {
    //     return
    // }

    const formData = new FormData();
    // formData.append('documents', [creditDocument]);
    // formData.append('credit_comment', comment);
    formData.append("credit_approval", true);

    try {
      const data = await CreditApproval(id, formData);
      if (data.status === true) {
        window.location.reload();
      }

      toast({
        title: "Sales approval successful",
        description: data?.message,
        status: "success",
        position: "top-left",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: error?.message,
        description: error?.message,
        status: "error",
        position: "top-left",
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Button
          bg="#5B2E4F"
          color="white"
          opacity={"1px"}
          fontSize="12px"
          fontWeight="500"
          fontFamily="satoshiBold"
          p="13px 35px"
          onClick={approve}
          isLoading
          loadingText="submitting"
        >
          APPROVE
        </Button>
      ) : (
        <Button
          bg="#5B2E4F"
          color="white"
          opacity={"1px"}
          fontSize="12px"
          fontWeight="500"
          fontFamily="satoshiBold"
          p="13px 35px"
          onClick={approve}
        >
          APPROVE
        </Button>
      )}
    </>
  );
};

export default CreditApprovalModal;
