import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Text,
  Flex,
  HStack,
  InputGroup,
  InputLeftElement,
  Input,
  Button,
  SimpleGrid,
  Select,
} from "@chakra-ui/react";
import { BiSearch } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import PaginatedLoans from "../components/PaginatedLoans";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import Loader from "../components/Loader/loader";
import { MdPendingActions, MdLibraryAdd } from "react-icons/md";
import { FcApproval } from "react-icons/fc";
import { useSelector, useDispatch } from "react-redux";
import { getLoanRequestAction } from "../redux/reducers/loanRequestReducer";
import { createMRTColumnHelper } from "material-react-table";
import moment from "moment";
import { motion } from "framer-motion";

const Dashboard = () => {
  const { loans, loading, meta } = useSelector((state) => state.loanRequest);
  const [pendingLoans, setPendingLoans] = useState();
  const [approvedLoans, setApprovedLoans] = useState();

  const dispatch = useDispatch();

  let searchQuery = useMemo(
    () => ({
      page: 1,
      pageLimit: 50,
    }),
    []
  );

  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  useEffect(() => {
    sessionStorage.clear();
    if (!role) navigate("/");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getLoanRequestAction(searchQuery));
  }, [dispatch, searchQuery]);

  const handleExportRows = (rows) => {
    const doc = new jsPDF();

    let exportedColumns = rows.map(
      ({ account_name, dob, email, phone, amount, first_name, last_name }) => ({
        account_name,
        dob,
        email,
        phone,
        amount,
        first_name,
        last_name,
      })
    );

    const tableHeaders = columns.map((c) => c.header);
    const tableData = exportedColumns.map((row) => Object.values(row));

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    let name = moment().format("LLL") + " loan.pdf";
    doc.save(name);
  };

  const handleInputChange = async (e) => {
    searchQuery = {
      ...searchQuery,
      searchQuery: e.target.value,
    };
  };

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("account_name", {
      header: "Account Name",
      size: 120,
    }),

    columnHelper.accessor("dob", {
      header: "DOB",
      size: 40,
    }),

    columnHelper.accessor("email", {
      header: "Email",
      size: 120,
    }),
    columnHelper.accessor("phone", {
      header: "Phone",
      size: 120,
    }),
    columnHelper.accessor("amount", {
      header: "Amount",
      size: 120,
    }),
    columnHelper.accessor("first_name", {
      header: "First Name",
      size: 120,
    }),
    columnHelper.accessor("last_name", {
      header: "Last Name",
      size: 120,
    }),
  ];

  useEffect(() => {
    const role = localStorage.getItem("role");
    const handleLoanFiltering = () => {
      if (role === "Reporting") {
        const pending = loans?.filter(
          (item) => item.sales_approval === "pending"
        );
        setPendingLoans(pending.length);
        const approved = loans?.filter(
          (item) => item.sales_approval === "approved"
        );
        setApprovedLoans(approved.length);
      }

      if (role === "Finance Ops") {
        const pending = loans?.filter(
          (item) => item.credit_approval === "pending"
        );
        setPendingLoans(pending.length);
        const approved = loans?.filter(
          (item) => item.credit_approval === "approved"
        );
        setApprovedLoans(approved.length);
      }

      if (role === "Finance Admin") {
        const pending = loans?.filter(
          (item) => item.finance_approval === "pending"
        );
        setPendingLoans(pending.length);
        const approved = loans?.filter(
          (item) => item.finance_approval === "approved"
        );
        setApprovedLoans(approved.length);
      }

      if (role === "Super Admin" || role === "Admin") {
        const pending = loans?.filter(
          (item) => item.final_approval === "pending"
        );
        setPendingLoans(pending.length);
        const approved = loans?.filter(
          (item) => item.final_approval === "approved"
        );
        setApprovedLoans(approved.length);
      }
    };

    if (loans && loans.length > 0) {
      handleLoanFiltering();
    }
  }, [loans]);

  const options = [
    {
      id: 1,
      name: "Number of Loans Pending",
      value: `${pendingLoans || 0}`,
      icon: (
        <MdPendingActions
          size="30px"
          color="#5B2E4F"
          style={{ margin: "10px" }}
        />
      ),
    },
    {
      id: 2,
      name: "Number of Loans Approved",
      value: `${approvedLoans || 0}`,
      icon: (
        <FcApproval size="30px" color="#5B2E4F" style={{ margin: "10px" }} />
      ),
    },
    {
      id: 3,
      name: "Total Number of Loans",
      value: `${meta?.totalVolume || 0}`,
      icon: (
        <MdLibraryAdd size="30px" color="#5B2E4F" style={{ margin: "10px" }} />
      ),
    },
  ];

  const handleDateRangeFilter = (e) => {
    const range = e.target.value;

    if (range === "7-days") {
      const from = moment().subtract(7, "days").format("YYYY-MM-DD");
      const to = moment().format("YYYY-MM-DD");
      searchQuery = {
        ...searchQuery,
        from,
        to,
      };
    }

    if (range === "1-month") {
      const from = moment().subtract(1, "month").format("YYYY-MM-DD");
      const to = moment().format("YYYY-MM-DD");
      searchQuery = {
        ...searchQuery,
        from,
        to,
      };
    }

    if (range === "3-months") {
      const from = moment().subtract(3, "month").format("YYYY-MM-DD");
      const to = moment().format("YYYY-MM-DD");
      searchQuery = {
        ...searchQuery,
        from,
        to,
      };
    }

    if (range === "6-months") {
      const from = moment().subtract(6, "month").format("YYYY-MM-DD");
      const to = moment().format("YYYY-MM-DD");
      searchQuery = {
        ...searchQuery,
        from,
        to,
      };
    }

    if (range === "1-year") {
      const from = moment().subtract(1, "year").format("YYYY-MM-DD");
      const to = moment().format("YYYY-MM-DD");
      searchQuery = {
        ...searchQuery,
        from,
        to,
      };
    }

    dispatch(getLoanRequestAction(searchQuery));
  };

  const handleStatusFilter = (e) => {
    const status = e.target.value;

    if (status === "all") {
      searchQuery = {
        page: 1,
        pageLimit: 50,
      };
      dispatch(getLoanRequestAction(searchQuery));
      return;
    }

    searchQuery = {
      ...searchQuery,
    };

    dispatch(getLoanRequestAction(searchQuery));
  };

  return (
    <Box
      bg="#F8F9FC"
      p={["20px 10px", "20px 10px", "20px 10px 20px 30px"]}
      h="100vh"
      w="100%"
    >
      <Text
        color="#5B2E4F"
        fontSize="18px"
        fontWeight="500"
        fontFamily="satoshiBold"
      >
        Dashboard
      </Text>

      <SimpleGrid
        spacing="20px"
        minChildWidth={["100px", "70px"]}
        justify="space-between"
        mt="30px"
      >
        {options.map((option) => (
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.9 }}
            key={option.id}
            style={{ cursor: "pointer" }}
          >
            <HStack
              display={"flex"}
              justify={"space-between"}
              key={option.id}
              bg="#F8F1F6"
              borderRadius="6px"
              p="20px"
            >
              <Box>
                <Text
                  fontSize={["13px", "15px"]}
                  color="#33343D"
                  fontWeight="500"
                  fontFamily="satoshiBold"
                >
                  {option.name}
                </Text>
                <Text
                  fontSize="21px"
                  fontFamily="satoshiBolder"
                  color="#5B2E4F"
                  lineHeight="28.35px"
                >
                  {option.value}
                </Text>
              </Box>
              <Box h="50px" w="50px" bg="#FFFFFF" borderRadius="4px">
                {option.icon}
              </Box>
            </HStack>
          </motion.div>
        ))}
      </SimpleGrid>

      <Box mt="20px">
        <Text
          color="#33343D"
          fontSize="15px"
          fontWeight="700"
          fontFamily="satoshiBolder"
        >
          Loan Requests
        </Text>
        <SimpleGrid
          spacing="20px"
          columns={[2, 2, 4, 4]}
          justify="space-between"
          mt="10px"
          p={["25px 10px", "25px 15px", "25px"]}
          bg="#FFFFFF"
          maxWidth="100%"
        >
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <BiSearch size="20px" color="#666666" />
            </InputLeftElement>
            <Input
              onChange={(e) => {
                handleInputChange(e);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  dispatch(getLoanRequestAction(searchQuery));
                }
              }}
              border="1px solid #E4E8F2"
              fontSize="13px"
              fontFamily="satoshiBold"
              color="#666666"
              type="tel"
              placeholder="Search name or enter keyword"
            />
          </InputGroup>
          <Flex border="1px solid #5B2E4F" bg="#FFFFFF" borderRadius="7px">
            <Flex p="0 20px" justify="center" align="center">
              <Select
                onChange={(e) => {
                  handleStatusFilter(e);
                }}
                placeholder="Select option"
              >
                <option defaultChecked value="all">
                  All
                </option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
              </Select>
            </Flex>
          </Flex>
          <Flex border="1px solid #5B2E4F" bg="#FFFFFF" borderRadius="7px">
            <Flex p="0 15px" justify="center" align="center">
              <Select
                onChange={(e) => {
                  handleDateRangeFilter(e);
                }}
                placeholder="Select option"
              >
                <option defaultChecked value="7-days">
                  7 days
                </option>
                <option value="1-month">1 month</option>
                <option value="3-months">3 months</option>
                <option value="6-months">6 months</option>
                <option value="1-year">1 Year</option>
              </Select>
            </Flex>
          </Flex>
          <Button
            onClick={() => {
              handleExportRows(loans);
            }}
            bg="#5B2E4F"
            color="white"
            fontSize="12px"
            fontWeight="500"
            p="0 30px"
          >
            EXPORT
          </Button>
        </SimpleGrid>

        {/* <ListofLoans loans={loans}/> */}

        {loading && (
          <Box
            display={"flex"}
            justify={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            marginTop={"20"}
            height={"50vh"}
          >
            <Loader />
          </Box>
        )}

        <PaginatedLoans loans={loans} meta={meta} />

        {!loading && loans?.length === 0 && (
          <Box
            display={"flex"}
            justify={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            marginTop={"20"}
          >
            <Text
              color="#5B2E4F"
              fontSize="18px"
              fontWeight="500"
              fontFamily="satoshiBold"
            >
              No loans found
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;
