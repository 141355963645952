import { Box, Text } from "@chakra-ui/react";
import { useState } from "react";
import ModalLayout from "../Layout/ModalLayout";
import { adminDeclineLoanDisbursement } from "../../services/axios";
import { toast } from "react-toastify";

const DeclineDisbursementModal = ({ loanRequest, isOpen, setIsOpen }) => {
  const [loading, setLoading] = useState(false);
  const declineDisbursementHandler = async () => {
    setLoading(true);
    try {
      await adminDeclineLoanDisbursement(loanRequest.request_id);
      setLoading(false);
      toast.success("Loan Disbursement Declined successfully");
      setTimeout(() => {
        setIsOpen(false);
        window.location.reload();
      }, 2000);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <ModalLayout
        successBtnText={loading ? "PLEASE WAIT..." : "DECLINE DISBURSEMENT"}
        cancelBtnText="CANCEL"
        title="Decline Loan Disbursement"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        loading={false}
        rejectHandler={declineDisbursementHandler}
      >
        <Box marginY="5">
          <Text color="#540A45" fontWeight="500" fontSize="15px">
            Are you sure you want to decline disbursement for this loan request?
            This action cannot be undone
          </Text>
        </Box>
      </ModalLayout>
    </>
  );
};

export default DeclineDisbursementModal;
