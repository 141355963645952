import { Button, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { SalesApproval, FinalApproval } from "../../services/axios";

const ApproveLoanModal = ({ id }) => {
  const toast = useToast();

  const role = localStorage.getItem("role");

  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);

  const approve = async () => {
    try {
      if (role === "Reporting") {
        setLoading(true);
        const data = await SalesApproval(id, reason);
        if (data) {
          toast({
            title: "Sales approval successful",
            description: data?.message,
            status: "success",
            position: "top-left",
            duration: 4000,
            isClosable: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }

      if (role === "Admin") {
        setLoading(true);

        const data = await FinalApproval(id, reason);
        if (data) {
          toast({
            title: "CEO approval successful",
            description: data?.message,
            status: "success",
            position: "top-left",
            duration: 4000,
            isClosable: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    } catch (error) {
      toast({
        title: "Sales Rejection ",
        description: error?.message,
        status: "error",
        position: "top-left",
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      setReason("");
    }
  };

  return (
    <>
      {loading ? (
        <Button
          bg="#5B2E4F"
          color="white"
          opacity={reason ? "0.38" : "1px"}
          fontSize="12px"
          fontWeight="500"
          fontFamily="satoshiBold"
          p="13px 35px"
          onClick={approve}
          isLoading
          loadingText="submitting"
        >
          APPROVE...
        </Button>
      ) : (
        <Button
          bg="#5B2E4F"
          color="white"
          opacity={reason ? "0.38" : "1px"}
          fontSize="12px"
          fontWeight="500"
          fontFamily="satoshiBold"
          p="13px 35px"
          onClick={approve}
        >
          APPROVE
        </Button>
      )}

      {/* <Button onClick={onOpen} Button bg="#5B2E4F" borderRadius="6px" fontSize={["9px", "12px"]} fontWeight="500" color="white" p={["2px 10px", "5px 37px"]} _hover={{ color: "#5B2E4F", border: "1px solid #5B2E4F", bg: "white" }} >
              APPROVE
          </Button> */}

      {/* <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>

                  <ModalHeader >
                      <Text color="#5B2E4F" fontWeight="700" fontFamily="satoshiBolder" fontSize="18px">Approval Details</Text>
                      <Text fontWeight="400" fontSize="14px" color="#33343D">Fill in the following details to approve this loan and move it forward.</Text>
                  </ModalHeader>

                  <ModalCloseButton />

                  <ModalBody>
                      <Box mt="20px">
                          <Text fontWeight="500" fontSize="14px" color="#33343D" value={reason}>Note to Approve</Text>
                          <Textarea value={reason} mt="10px" onChange={(e) => setReason(e.currentTarget.value)} className='form-input' />
                      </Box>
                  </ModalBody>

                  <ModalFooter border="1px solid #5B2E4F" mt="30px">

                      <Button fontSize="12px" border="1px solid #5B2E4F" color="#5B2E4F" mr={3} onClick={onClose}>
                          Close
                      </Button>
                       
                       { 
                          loading ?
                              <Button bg="#5B2E4F" color="white" opacity={!reason ? "0.38" : '1px'} fontSize="12px" fontWeight="500" fontFamily="satoshiBold" p="13px 35px" onClick={approve} isLoading loadingText="submitting">SUBMIT</Button>
                              :
                              <Button bg="#5B2E4F" color="white" opacity={!reason ? "0.38" : '1px'} fontSize="12px" fontWeight="500" fontFamily="satoshiBold" p="13px 35px" onClick={approve} >SUBMIT...</Button>
                        }

                  </ModalFooter>
              </ModalContent>
          </Modal> */}
    </>
  );
};

export default ApproveLoanModal;
