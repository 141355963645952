import { Box, Img } from '@chakra-ui/react'
import React from 'react'
import { Outlet } from 'react-router-dom'

import MenuLinks from './MenuLinks'
import NavBar from '../Navbar'
import logo from "../../assets/Hervest-logo.svg"
const MenuSidebar = () => {
    return (
        <Box>
            <Box bg="#FFFFFF" 
                w="15rem"
                borderRightWidth="thin"
                borderColor="gray.100"
                display={["none", "none","block"]}
                flexShrink="0"
                h="100%"
                z-index="1"
                position="fixed"
                overflowX="hidden"
                top="0" 
                borderRight="0.17px solid #5B2E4F">
                <Img src={logo} m="0 auto" p="20px 0" objectFit="contain" w="50%" />
                <Box pt="10px" >
                    <MenuLinks />
                </Box>
            </Box>
            <NavBar />
            <Box ml={[0, 0, "15rem"]}  mt="0px" >
                <Outlet />
            </Box>
        </Box>
    )
}

export default MenuSidebar
